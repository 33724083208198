import React, { ReactNode } from 'react'

import { UpSeatLogo } from '@/assets/upseat-logo'
import { useNavigate } from 'react-router-dom'
import useScreenSize from '@/hooks/useScreenSize'

type PublicLayoutProps = {
  children: ReactNode
}

const PublicLayout: React.FC<PublicLayoutProps> = ({ children }) => {
  const screenSize = useScreenSize()
  const navigate = useNavigate()

  return (
    <div
      className={`app-view w-full bg-purple overflow-hidden ${screenSize.width > 1024 && 'pl-[33vw] pr-[33vw]'}`}
    >
      <div
        className={`h-[15vh] left-0 [&>figure]:h-fit [&>figure]:w-[33%] [&>figure>svg]:w-[100%] [&>figure>svg]:h-[100%] w-full flex justify-center items-center`}
      >
        <figure onClick={() => navigate('/')}>
          <UpSeatLogo />
        </figure>
      </div>
      {children}
    </div>
  )
}

export default PublicLayout
