import { AnimatePresence, motion } from 'framer-motion'
import { transition, transitionOut } from '@/utils'

import { ArrowRight } from 'lucide-react'
import { ArrowRightLong } from '@/assets/ArrowRightLong'
import { Button } from '@/components/ui/button'
import { UpSeatLogo } from '@/assets/upseat-logo'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import useScreenSize from '@/hooks/useScreenSize'

export const Home = () => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const screenSize = useScreenSize()

  return (
    <main className="bg-purple overflow-hidden w-full h-[100svh]">
      <AnimatePresence>
        <motion.div
          transition={transition}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: transitionOut }}
          className="relative flex flex-col gap-1 w-full h-full justify-center items-center"
        >
          <div className="absolute [&>svg]:w-[50%] w-full flex justify-center items-center">
            <UpSeatLogo />
          </div>
          <div
            className={`absolute bottom-4 w-full flex flex-col gap-4 px-4 flex flex-col ${screenSize.width > 1280 ? 'pl-[33vw] pr-[33vw]' : ''}`}
          >
            <Button
              variant="primaryYellow"
              id="home-signup-btn"
              size={'large'}
              onClick={() => callRouteWithDirection('/sign-up', false, 1)}
              className="w-full"
              data-testid="wallet-login-button"
            >
              {`Sign up`}
              <ArrowRightLong />
            </Button>
            <Button
              variant="secondaryWhite"
              id="home-login-btn"
              className="text-white capitalize w-full"
              onClick={() => callRouteWithDirection('/login', false, 1)}
              data-testid="wallet-login-button"
            >
              {`login`}
            </Button>
          </div>
        </motion.div>
      </AnimatePresence>
    </main>
  )
}
