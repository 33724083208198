import { transitionEasing, transitionEasingReverse } from '@/lib/global-vars'

import React from 'react'
import { motion } from 'framer-motion'
import useScreenSize from '@/hooks/useScreenSize'

type AllScreenBlurProps = {
  children: React.ReactNode
  dimApp?: boolean
  dark?: boolean
  singleAction?: boolean
  callback?: Function
  close?: Function
}

export const AllScreenBlur = ({
  children,
  dimApp,
  dark,
  singleAction,
  callback,
  close,
}: AllScreenBlurProps) => {
  const screenSize = useScreenSize()

  const transition = {
    ease: transitionEasing,
    duration: 0.425,
  }
  const transitionOut = {
    ease: transitionEasingReverse,
    duration: 0.225,
  }

  const closeIfOutside = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget === e.target && close) {
      close()
    }
  }

  return (
    <motion.div
      className={`${screenSize.width > 1280 ? 'mx-[33vw] w-[-webkit-fill-available] w-[-moz-available]' : 'w-full'} bottom-sheet fixed z-30 left-0 top-0 h-full ${
        dimApp ? 'bg-purple/70' : ''
      }`}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => closeIfOutside(e)}
    >
      <motion.div
        transition={transition}
        initial={{ opacity: 0, translateY: 0, filter: 'blur(10px)' }}
        animate={{ opacity: 1, translateY: 40, filter: 'blur(0)' }}
        exit={{
          opacity: 0,
          translateY: 0,
          filter: 'blur(5px)',
          transition: transitionOut,
        }}
      >
        <div
          className={`bottom-sheet-content absolute h-full bottom-0 left-0 w-full p-6 rounded-t-4xl`}
        >
          {children}
        </div>
      </motion.div>
    </motion.div>
  )
}
