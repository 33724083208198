import { z } from 'zod'

export const personalDetailsFormSchema = z
  .object({
    firstName: z.string().min(1, { message: 'First Name is required' }),
    lastName: z.string().min(1, { message: 'Last Name is required' }),
    phoneNumber: z
      .string()
      .min(1, { message: 'Phone Number is required' })
      .or(z.string().optional()),
  })
  .refine((data) => !data.phoneNumber || data.phoneNumber.length > 9, {
    message: 'Enter a valid phone number. Check the country code.',
    path: ['phoneNumber'],
  })
  .refine((data) => !data.phoneNumber || !isNaN(Number(data.phoneNumber)), {
    message: 'Invalid phone number',
    path: ['phoneNumber'],
  })

export type personalDetailsFormSchemaType = z.infer<
  typeof personalDetailsFormSchema
>
