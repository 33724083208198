export const UpSeatLogoSmall = () => (
  <svg
    width="338"
    height="323"
    viewBox="0 0 338 323"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="337.356" height="323" rx="53.8333" fill="#19162C" />
    <path
      d="M169.561 181.816V217.131H110.56V200.335C100.224 213.542 87.3041 220.145 71.8001 220.145C59.885 220.145 50.9127 216.413 44.8834 208.948C38.9976 201.483 36.0547 191.219 36.0547 178.155V132.72H26.5801V97.4053H85.5814V168.035C85.5814 172.772 86.5863 176.289 88.5961 178.586C90.7494 180.739 93.9076 181.816 98.0708 181.816C102.234 181.816 105.32 180.739 107.33 178.586C109.483 176.289 110.56 172.772 110.56 168.035V132.72H98.9321V97.4053H160.087V181.816H169.561Z"
      fill="#F4FF68"
    />
    <path
      d="M177.646 244.047L186.619 242.253L184.107 132.72H174.632V97.4053H204.563H233.633V112.048C237.222 107.454 241.816 103.363 247.415 99.774C253.157 96.1851 259.689 94.3906 267.01 94.3906C281.653 94.3906 292.635 99.8457 299.956 110.756C307.421 121.666 311.153 137.17 311.153 157.268C311.153 177.366 307.421 192.87 299.956 203.78C292.635 214.69 281.653 220.145 267.01 220.145C259.689 220.145 253.157 218.351 247.415 214.762C241.816 211.173 237.222 207.082 233.633 202.488L233.274 240.458C233.274 240.458 235.685 240.458 238.657 240.458C241.631 240.458 247.63 240.458 247.63 240.458L248.491 260.197C248.491 260.197 211.807 260.197 197.385 260.197C182.963 260.197 177.646 261.992 177.646 261.992V244.047ZM246.123 181.816C250.286 181.816 253.372 180.739 255.382 178.586C257.535 176.289 258.612 172.772 258.612 168.035V146.501C258.612 141.764 257.535 138.319 255.382 136.165C253.372 133.868 250.286 132.72 246.123 132.72C241.959 132.72 238.801 133.868 236.648 136.165C234.638 138.319 233.633 141.764 233.633 146.501V168.035C233.633 172.772 234.638 176.289 236.648 178.586C238.801 180.739 241.959 181.816 246.123 181.816Z"
      fill="#F4FF68"
    />
  </svg>
)
