/* eslint-disable max-lines */
import { Plus, Star } from 'lucide-react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { isCurrentSeat, selectSeat } from '../..'
import { useEffect, useState } from 'react'

import { Circle } from '@/assets/mapSeats/Kiln/Circle'
import { CircleCentral } from './CircleCentral'
import { PopModal } from '../../../modals/PopModal'
import { Seat } from '@/types'
import { StallsCentral } from './StallsCentral'
import { motion } from 'framer-motion'
import useEventStore from '@/context/useEventStore'
import useExistingBookingStore from '@/context/useExistingBookingStore'
import useScreenSize from '@/hooks/useScreenSize'
import { useSeatMapFilterStore } from '@/context/useSeatMapFilterStore'

export const KilnSeatMap = () => {
  const { discountRange, setDiscountRange } = useSeatMapFilterStore()
  const { booking } = useExistingBookingStore()
  const { mapSeats, priceBands, selectedSeats, setSelectedSeats } =
    useEventStore()
  const [seatAsideWarning, setSeatAsideWarning] = useState<boolean>(false)
  const screenSize = useScreenSize()

  useEffect(() => {
    setDiscountRange([0, priceBands.length - 1])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const mapSeatsByRow = (mapSeats: Seat[], location?: string) => {
    const seatsByRow = {} as Record<string, Seat[]>

    mapSeats
      .filter((seat) => seat.location === location)
      .forEach((seat: Seat) => {
        const { row } = seat
        if (!seatsByRow[row]) {
          seatsByRow[row] = []
        }

        seatsByRow[row]?.push(seat)
      })

    // Convert seatsByRow object to an array
    const result = []

    // Convert seatsByRow object to an array of objects with 'row' and 'seats' properties
    for (const row in seatsByRow) {
      if (seatsByRow.hasOwnProperty(row)) {
        result.push({ row, seats: seatsByRow[row] })
      }
    }
    return result
  }

  const filterResults = (tier: number, discountRange: number[]) => {
    // check if the tier is within the discount range
    if (tier - 1 >= discountRange[0] && tier - 1 <= discountRange[1]) {
      return
    } else {
      return 'opacity-[.2] pointer-events-none'
    }
  }

  return (
    <div className="flex mb-[10vh] h-[80vh]">
      <TransformWrapper
        disabled // disable zooming
        initialScale={1}
        minScale={0.8}
        limitToBounds
        centerZoomedOut
        centerOnInit
        disablePadding
        wheel={{
          disabled: true,
          wheelDisabled: true,
          touchPadDisabled: true,
        }}
        doubleClick={{
          disabled: true,
        }}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <>
            <TransformComponent>
              <div className="h-screen w-screen flex flex-row flex-wrap content-start relative bottom-[10vh]">
                <div className="seat-map rounded-containers h-fit w-full px-1 pb-8 flex flex-col items-center">
                  <span
                    className={`relative block h-[20px] mx-[7rem] ${screenSize.width > 1280 ? 'w-[30%]' : 'w-[80%]'} text-[12px] bg-grey2 rounded-[0_0_10px_10px] mb-2 uppercase flex justify-center items-center`}
                  >
                    {`stage`}
                  </span>
                  <div className="absolute w-[stretch]">
                    <span className="absolute w-[inherit] border border-b border-grey2 top-0 mx-4" />
                  </div>

                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                    className="relative flex justify-center"
                  >
                    <div className="absolute border border-grey2 pb-8 pt-0 top-[3rem] rounded-[10px] flex flex-col items-center w-[315px] h-[20rem]"></div>
                    <div className="absolute pb-8 pt-[3rem] rounded-[10px] flex flex-col items-center w-[315px] z-[1]">
                      <p className="text-grey w-full text-center pt-2 uppercase text-[12px]">{`Stalls`}</p>
                      <div className="flex gap-2">
                        <div className="seat-map h-fit rounded-containers">
                          {mapSeats &&
                            mapSeatsByRow(mapSeats, 'Side Stalls')
                              .filter(
                                (seatAvailable) => seatAvailable.row === 'AA',
                              )
                              .map((row) => {
                                return (
                                  <div
                                    className={`flex items-center w-full mb-2 ${row.row}`}
                                    key={row.row}
                                  >
                                    <div className="w-full relative flex flex-col justify-center gap-1">
                                      {row.seats
                                        .filter(
                                          (seat) => parseInt(seat.number) > 11,
                                        )
                                        .reverse()
                                        .map((seat) => {
                                          return (
                                            <div
                                              key={seat.seat_id}
                                              onClick={() =>
                                                selectSeat(
                                                  seat,
                                                  selectedSeats,
                                                  setSelectedSeats,
                                                  setSeatAsideWarning,
                                                )
                                              }
                                              style={{
                                                width: `13px`,
                                                height: `13px`,
                                              }}
                                              className={`seat flex items-center justify-center ${row.row + seat.number}
                      ${selectedSeats.includes(seat) && 'selected-seat'}
                      ${
                        isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) && 'current-seat pointer-events-none'
                      }
                      ${
                        !selectedSeats.includes(seat) &&
                        !isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) &&
                        filterResults(seat.tier, discountRange)
                      }
                      ${
                        !selectedSeats.includes(seat) &&
                        !isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) &&
                        booking?.tickets.length === selectedSeats.length &&
                        'opacity-[.2] pointer-events-none'
                      }
                      `}
                                            >
                                              <motion.div
                                                initial={{ scale: 0 }}
                                                animate={{
                                                  scale: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [1, 2, 2, 1, 1]
                                                    : 1,
                                                  rotate: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [0, 0, -270, -270, 0]
                                                    : 0,
                                                  borderRadius: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [
                                                        '20%',
                                                        '20%',
                                                        '50%',
                                                        '50%',
                                                        '20%',
                                                      ]
                                                    : '0%',
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    backgroundColor: seat.tier
                                                      ? 'var(--tier' +
                                                        seat.tier +
                                                        ')'
                                                      : 'grey',
                                                  }}
                                                  className={`relative flex items-center justify-center h-[14px] w-[14px] block rounded-[4px]
                          ${selectedSeats.includes(seat) && `!bg-blue`}
                                  ${
                                    isCurrentSeat(
                                      booking,
                                      seat.row + seat.number.toString(),
                                      seat.location,
                                    ) &&
                                    `!bg-[white] border-[2px] border-[black]`
                                  }`}
                                                >
                                                  {isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  ) && (
                                                    <Plus
                                                      color="black"
                                                      style={{
                                                        rotate: '45deg',
                                                        position: 'absolute',
                                                      }}
                                                      width={18}
                                                      height={20}
                                                    />
                                                  )}
                                                  {selectedSeats.includes(
                                                    seat,
                                                  ) && (
                                                    <Star
                                                      color="white"
                                                      fill="white"
                                                      size={8}
                                                    />
                                                  )}
                                                </span>
                                              </motion.div>
                                            </div>
                                          )
                                        })}
                                    </div>
                                  </div>
                                )
                              })}
                        </div>
                        <StallsCentral
                          booking={booking}
                          discountRange={discountRange}
                          selectedSeats={selectedSeats}
                          setSelectedSeats={setSelectedSeats}
                          setSeatAsideWarning={setSeatAsideWarning}
                          mapSeatsByRow={mapSeatsByRow}
                        />
                        <div className="seat-map h-fit rounded-containers">
                          {mapSeats &&
                            mapSeatsByRow(mapSeats, 'Side Stalls')
                              .filter(
                                (seatAvailable) => seatAvailable.row === 'AA',
                              )
                              .map((row) => {
                                return (
                                  <div
                                    className="flex items-center w-full mb-2"
                                    key={row.row}
                                  >
                                    <div className="w-full relative flex flex-col justify-center gap-1">
                                      {row.seats
                                        .filter(
                                          (seat) => parseInt(seat.number) <= 11,
                                        )
                                        .map((seat, index) => {
                                          return (
                                            <div
                                              key={seat.seat_id}
                                              onClick={() =>
                                                selectSeat(
                                                  seat,
                                                  selectedSeats,
                                                  setSelectedSeats,
                                                  setSeatAsideWarning,
                                                )
                                              }
                                              style={{
                                                width: `13px`,
                                                height: `13px`,
                                              }}
                                              className={`seat flex items-center justify-center ${row.row + seat.number}
                      ${selectedSeats.includes(seat) && 'selected-seat'}
                      ${
                        isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) && 'current-seat pointer-events-none'
                      }
                      ${
                        !selectedSeats.includes(seat) &&
                        !isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) &&
                        filterResults(seat.tier, discountRange)
                      }
                      ${
                        !selectedSeats.includes(seat) &&
                        !isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) &&
                        booking?.tickets.length === selectedSeats.length &&
                        'opacity-[.2] pointer-events-none'
                      }
                      `}
                                            >
                                              <motion.div
                                                initial={{ scale: 0 }}
                                                animate={{
                                                  scale: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [1, 2, 2, 1, 1]
                                                    : 1,
                                                  rotate: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [0, 0, -270, -270, 0]
                                                    : 0,
                                                  borderRadius: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [
                                                        '20%',
                                                        '20%',
                                                        '50%',
                                                        '50%',
                                                        '20%',
                                                      ]
                                                    : '0%',
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    backgroundColor: seat.tier
                                                      ? 'var(--tier' +
                                                        seat.tier +
                                                        ')'
                                                      : 'grey',
                                                  }}
                                                  className={`relative flex items-center justify-center h-[14px] w-[14px] block rounded-[4px]
                          ${selectedSeats.includes(seat) && `!bg-blue`}
                                  // ${
                                    isCurrentSeat(
                                      booking,
                                      seat.row + seat.number.toString(),
                                      seat.location,
                                    ) &&
                                    `!bg-[white] border-[2px] border-[black]`
                                  }`}
                                                >
                                                  {isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  ) && (
                                                    <Plus
                                                      color="black"
                                                      style={{
                                                        rotate: '45deg',
                                                        position: 'absolute',
                                                      }}
                                                      width={18}
                                                      height={20}
                                                    />
                                                  )}
                                                  {selectedSeats.includes(
                                                    seat,
                                                  ) && (
                                                    <Star
                                                      color="white"
                                                      fill="white"
                                                      size={8}
                                                    />
                                                  )}
                                                </span>
                                              </motion.div>
                                            </div>
                                          )
                                        })}
                                    </div>
                                  </div>
                                )
                              })}
                        </div>
                      </div>
                    </div>

                    <div className="absolute w-[100vw] top-[7rem]">
                      <div className="flex justify-center relative">
                        <Circle />
                        <div className="seat-map absolute h-fit top-[18px] left-[12px] rounded-containers">
                          {mapSeats &&
                            mapSeatsByRow(mapSeats, 'Side Circle')
                              .filter(
                                (seatAvailable) => seatAvailable.row === 'BB',
                              )
                              .map((row) => {
                                return (
                                  <div
                                    className="flex items-center w-full mb-2"
                                    key={row.row}
                                  >
                                    <div className="w-full relative flex flex-col justify-center gap-1">
                                      {row.seats
                                        .filter(
                                          (seat) => parseInt(seat.number) > 11,
                                        )
                                        .reverse()
                                        .map((seat, index) => {
                                          return (
                                            <div
                                              key={seat.seat_id}
                                              onClick={() =>
                                                selectSeat(
                                                  seat,
                                                  selectedSeats,
                                                  setSelectedSeats,
                                                  setSeatAsideWarning,
                                                )
                                              }
                                              style={{
                                                width: `13px`,
                                                height: `13px`,
                                              }}
                                              className={`seat flex items-center justify-center ${row.row + seat.number}
                      ${selectedSeats.includes(seat) && 'selected-seat'}
                      ${
                        isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) && 'current-seat pointer-events-none'
                      }
                      ${
                        !selectedSeats.includes(seat) &&
                        !isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) &&
                        filterResults(seat.tier, discountRange)
                      }
                      ${
                        !selectedSeats.includes(seat) &&
                        !isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) &&
                        booking?.tickets.length === selectedSeats.length &&
                        'opacity-[.2] pointer-events-none'
                      }
                      `}
                                            >
                                              <motion.div
                                                initial={{ scale: 0 }}
                                                animate={{
                                                  scale: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [1, 2, 2, 1, 1]
                                                    : 1,
                                                  rotate: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [0, 0, -270, -270, 0]
                                                    : 0,
                                                  borderRadius: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [
                                                        '20%',
                                                        '20%',
                                                        '50%',
                                                        '50%',
                                                        '20%',
                                                      ]
                                                    : '0%',
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    backgroundColor: seat.tier
                                                      ? 'var(--tier' +
                                                        seat.tier +
                                                        ')'
                                                      : 'grey',
                                                  }}
                                                  className={`relative flex items-center justify-center h-[14px] w-[14px] block rounded-[4px]
                          ${selectedSeats.includes(seat) && `!bg-blue`}
                                  ${
                                    isCurrentSeat(
                                      booking,
                                      seat.row + seat.number.toString(),
                                      seat.location,
                                    ) &&
                                    `!bg-[white] border-[2px] border-[black]`
                                  }`}
                                                >
                                                  {isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  ) && (
                                                    <Plus
                                                      color="black"
                                                      style={{
                                                        rotate: '45deg',
                                                        position: 'absolute',
                                                      }}
                                                      width={18}
                                                      height={20}
                                                    />
                                                  )}
                                                  {selectedSeats.includes(
                                                    seat,
                                                  ) && (
                                                    <Star
                                                      color="white"
                                                      fill="white"
                                                      size={8}
                                                    />
                                                  )}
                                                </span>
                                              </motion.div>
                                            </div>
                                          )
                                        })}
                                    </div>
                                  </div>
                                )
                              })}
                        </div>

                        <div className="seat-map absolute h-fit top-[18px] right-[14px] rounded-containers">
                          {mapSeats &&
                            mapSeatsByRow(mapSeats, 'Side Circle')
                              .filter(
                                (seatAvailable) => seatAvailable.row === 'BB',
                              )
                              .map((row) => {
                                return (
                                  <div
                                    className="flex items-center w-full mb-2"
                                    key={row.row}
                                  >
                                    <div className="w-full relative flex flex-col justify-center gap-1">
                                      {row.seats
                                        .filter(
                                          (seat) => parseInt(seat.number) <= 11,
                                        )
                                        .map((seat, index) => {
                                          return (
                                            <div
                                              key={seat.seat_id}
                                              onClick={() =>
                                                selectSeat(
                                                  seat,
                                                  selectedSeats,
                                                  setSelectedSeats,
                                                  setSeatAsideWarning,
                                                )
                                              }
                                              style={{
                                                width: `13px`,
                                                height: `13px`,
                                              }}
                                              className={`seat flex items-center justify-center ${row.row + seat.number}
                      ${selectedSeats.includes(seat) && 'selected-seat'}
                      ${
                        isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) && 'current-seat pointer-events-none'
                      }
                      ${
                        !selectedSeats.includes(seat) &&
                        !isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) &&
                        filterResults(seat.tier, discountRange)
                      }
                      ${
                        !selectedSeats.includes(seat) &&
                        !isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) &&
                        booking?.tickets.length === selectedSeats.length &&
                        'opacity-[.2] pointer-events-none'
                      }
                      `}
                                            >
                                              <motion.div
                                                initial={{ scale: 0 }}
                                                animate={{
                                                  scale: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [1, 2, 2, 1, 1]
                                                    : 1,
                                                  rotate: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [0, 0, -270, -270, 0]
                                                    : 0,
                                                  borderRadius: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [
                                                        '20%',
                                                        '20%',
                                                        '50%',
                                                        '50%',
                                                        '20%',
                                                      ]
                                                    : '0%',
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    backgroundColor: seat.tier
                                                      ? 'var(--tier' +
                                                        seat.tier +
                                                        ')'
                                                      : 'grey',
                                                  }}
                                                  className={`relative flex items-center justify-center h-[14px] w-[14px] block rounded-[4px]
                          ${selectedSeats.includes(seat) && `!bg-blue`}
                                  ${
                                    isCurrentSeat(
                                      booking,
                                      seat.row + seat.number.toString(),
                                      seat.location,
                                    ) &&
                                    `!bg-[white] border-[2px] border-[black]`
                                  }`}
                                                >
                                                  {isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  ) && (
                                                    <Plus
                                                      color="black"
                                                      style={{
                                                        rotate: '45deg',
                                                        position: 'absolute',
                                                      }}
                                                      width={18}
                                                      height={20}
                                                    />
                                                  )}
                                                  {selectedSeats.includes(
                                                    seat,
                                                  ) && (
                                                    <Star
                                                      color="white"
                                                      fill="white"
                                                      size={8}
                                                    />
                                                  )}
                                                </span>
                                              </motion.div>
                                            </div>
                                          )
                                        })}
                                    </div>
                                  </div>
                                )
                              })}
                        </div>

                        <CircleCentral
                          booking={booking}
                          discountRange={discountRange}
                          selectedSeats={selectedSeats}
                          setSelectedSeats={setSelectedSeats}
                          setSeatAsideWarning={setSeatAsideWarning}
                          mapSeatsByRow={mapSeatsByRow}
                        />
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>

      <PopModal
        title={''}
        description={`Please select a seat next to the already selected seat`}
        buttonText={`Got it, let’s go`}
        open={seatAsideWarning}
        onClick={() => setSeatAsideWarning(false)}
      />
    </div>
  )
}
