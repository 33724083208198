import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
  paymentCardFormSchema,
  paymentCardFormSchemaType,
} from '@/lib/validation-schemas/paymentCardFormSchema'

import { Button } from '@/components/ui/button'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import { Input } from '@/components/ui/input'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { zodResolver } from '@hookform/resolvers/zod'

interface AddPaymentDetailsProps {
  setOpen: (value: boolean) => void
}

export const AddPaymentDetails = ({ setOpen }: AddPaymentDetailsProps) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<paymentCardFormSchemaType>({
    resolver: zodResolver(paymentCardFormSchema),
  })

  const onSubmit: SubmitHandler<paymentCardFormSchemaType> = (data) => {
    console.log('DATA SENT ->', data)
  }

  return (
    <DefaultLayout>
      <RouteTransition>
        <div className="flex flex-col justify-start gap-4">
          <HeadlineBar
            title={`add payment details`}
            goBack={() => setOpen(false)}
          />
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white overflow-scroll flex flex-col gap-4 text-purple h-[90svh] p-4 py-6"
          autoComplete={'off'}
        >
          <Controller
            name="name"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <div className="flex flex-col">
                <Input
                  className={`${errors.name?.message && 'border-red'} py-4`}
                  placeholder="Name on Card"
                  {...field}
                />

                {errors.name && (
                  <p className="text-[12px] text-pretty pl-2 text-red">
                    {errors.name.message}
                  </p>
                )}
              </div>
            )}
          />

          <Controller
            name="number"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <div className="flex flex-col">
                <Input
                  className={`${errors.number?.message && 'border-red'} py-4`}
                  placeholder="Card number"
                  {...field}
                />

                {errors.number && (
                  <p className="text-[12px] text-pretty pl-2 text-red">
                    {errors.number.message}
                  </p>
                )}
              </div>
            )}
          />

          <div className="flex gap-2">
            <Controller
              name="expiryDate"
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <div className="flex flex-col">
                  <Input
                    className={`${
                      errors.expiryDate?.message && 'border-red'
                    } py-4`}
                    placeholder="MM/YY"
                    {...field}
                  />
                  {errors.expiryDate && (
                    <p className="text-[12px] text-pretty pl-2 text-red">
                      {errors.expiryDate.message}
                    </p>
                  )}
                </div>
              )}
            />

            <Controller
              name="cvc"
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <div className="flex flex-col">
                  <Input
                    type="password"
                    className={`${errors.cvc?.message && 'border-red'} py-4`}
                    placeholder="CVC"
                    {...field}
                  />

                  {errors.cvc && (
                    <p className="text-[12px] text-pretty pl-2 text-red">
                      {errors.cvc.message}
                    </p>
                  )}
                </div>
              )}
            />
          </div>

          <Button
            variant="primaryDark"
            className="w-full font-normal py-4 mt-4"
          >{`Add Card`}</Button>
        </form>
      </RouteTransition>
    </DefaultLayout>
  )
}
