import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/utils'
import { TicketStateMap, TicketStates } from '@/types'

const buttonVariants = cva(
  'inline-flex gap-2 items-center justify-center bg-purple font-bold capitalize w-fit text-[yellow] whitespace-nowrap text-base font-body font-semibold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300',
  {
    variants: {
      size: {
        default: 'px-6 py-2',
        small: 'px-4 py-2',
        textOnly: 'px-1 py-1',
      },
      disabled: {
        true: 'pointer-events-none bg-purple5 text-purple3',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  disabled?: boolean
  ticketState: number
}

const TicketButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, size, disabled, asChild = false, ticketState, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    const ticketStateText =
      TicketStateMap[ticketState] ?? TicketStates.VIEW_TICKET // Set the text based on the ticketState

    disabled = ticketState === 4

    return (
      <Comp
        className={cn(buttonVariants({ size, disabled, className }))}
        ref={ref}
        {...props}
      >
        {ticketStateText}
      </Comp>
    )
  },
)
TicketButton.displayName = 'Button'

export { TicketButton, buttonVariants }
