import { Barcode } from 'lucide-react'
import { Booking } from '@/types'
import { TicketButton } from './TicketButton'
import TicketImage from './TicketImage'
import { TicketInfo } from './TicketInfo'

type TicketCardProps = {
  bookingTickets: Booking
  upgradeTicket?: (
    order_id: string,
    instance_uid: string,
    ticketPrice: number,
  ) => void
}

export function TicketCard({ bookingTickets, upgradeTicket }: TicketCardProps) {
  bookingTickets.tickets.sort((a, b) => {
    const matchA = a.seat_name.match(/\d+/)
    const matchB = b.seat_name.match(/\d+/)
    const numA = matchA ? parseInt(matchA[0]) : 0
    const numB = matchB ? parseInt(matchB[0]) : 0
    return numA - numB
  })

  return (
    <div
      id="ticket-card"
      className={`${
        bookingTickets.upseated
          ? 'shadow-[0_0_10px_0px_yellow]'
          : 'shadow-[0_0_8px_0px_#CDC3FB]'
      } rounded-[35px] bg-white flex flex-col items-center w-full h-full max-h-[80svh] max-w-[85vw] mb-[10px]`}
    >
      <div className="overflow-hidden pointer-events-none w-full h-full rounded-[35px] m-[-1px]">
        <TicketImage src={bookingTickets.tickets[0].thumbnail_url} />
      </div>
      <div className="flex flex-col gap-2 items-center my-4">
        <TicketInfo ticketInfo={bookingTickets} upgraded embeddedInTicket />
        {navigator.onLine ? (
          bookingTickets.upseated ? (
            <span className="bg-purple p-3 rounded-[30px] relative">
              <Barcode color={'yellow'} />
            </span>
          ) : !bookingTickets.upseated ? (
            <div className="relative flex gap-2 my-2">
              <TicketButton
                ticketState={bookingTickets.state.code}
                onClick={() => {
                  if (bookingTickets.state.code === 0) {
                    upgradeTicket &&
                      upgradeTicket(
                        bookingTickets.order_id,
                        bookingTickets.instance_uid,
                        bookingTickets.tickets[0].price,
                      )
                  }
                }}
              />
            </div>
          ) : null
        ) : null}
      </div>
    </div>
  )
}
