import { Basket, Booking } from '../types'

import { ReactNode } from 'react'
import { create } from 'zustand'

// Define a type for the user object

// Define the store's state
type WalletState = {
  wallet: Booking[] | undefined
  basket: Basket | undefined
  setWallet: (booking: Booking[] | undefined) => void
  removeUpBooking: () => void
  setBasket: (basket: Basket) => void
  removeBasket: () => void
}

// Create the store
export const useTicketsStore = create<WalletState>((set) => ({
  wallet: undefined,
  basket: undefined,
  setWallet: (wallet) => set({ wallet }),
  removeUpBooking: () => set({ wallet: undefined }),
  setBasket: (basket) => set({ basket }),
  removeBasket: () => set({ basket: undefined }),
}))

export const TicketsStoreProvider = ({ children }: { children: ReactNode }) => {
  return <>{children}</>
}
