import { CountryCode } from '@/types'

export const transitionEasing = [0.25, 0.625, 0.01, 0.98]
export const transitionEasingReverse = [0.98, 0.01, 0.75, 0.75]

export const countryCodes: CountryCode[] = [
  { acronym: 'GB', code: '+44', digits: '44' },
  { acronym: 'AT', code: '+43', digits: '43' },
  { acronym: 'BE', code: '+32', digits: '32' },
  { acronym: 'BG', code: '+359', digits: '359' },
  { acronym: 'HR', code: '+385', digits: '385' },
  { acronym: 'CY', code: '+357', digits: '357' },
  { acronym: 'CZ', code: '+420', digits: '420' },
  { acronym: 'DK', code: '+45', digits: '45' },
  { acronym: 'EE', code: '+372', digits: '372' },
  { acronym: 'FI', code: '+358', digits: '358' },
  { acronym: 'FR', code: '+33', digits: '33' },
  { acronym: 'DE', code: '+49', digits: '49' },
  { acronym: 'GR', code: '+30', digits: '30' },
  { acronym: 'HU', code: '+36', digits: '36' },
  { acronym: 'IE', code: '+353', digits: '353' },
  { acronym: 'IT', code: '+39', digits: '39' },
  { acronym: 'LV', code: '+371', digits: '371' },
  { acronym: 'LT', code: '+370', digits: '370' },
  { acronym: 'LU', code: '+352', digits: '352' },
  { acronym: 'MT', code: '+356', digits: '356' },
  { acronym: 'NL', code: '+31', digits: '31' },
  { acronym: 'PL', code: '+48', digits: '48' },
  { acronym: 'PT', code: '+351', digits: '351' },
  { acronym: 'RO', code: '+40', digits: '40' },
  { acronym: 'SK', code: '+421', digits: '421' },
  { acronym: 'SI', code: '+386', digits: '386' },
  { acronym: 'ES', code: '+34', digits: '34' },
  { acronym: 'SE', code: '+46', digits: '46' },
]

export const mobileDevices = [
  'Apple (iPhone 15 Pro)',
  'Apple (iPhone 15)',
  'Apple (iPhone 14 Pro)',
  'Apple (iPhone 14)',
  'Apple (iPhone 13 Mini)',
  'Apple (iPhone 13)',
  'Apple (iPhone 12)',
  'Apple (iPhone X)',
  'Samsung (Galaxy S24 Ultra)',
  'Google (Pixel 8 Pro)',
  'Google (Pixel 7a)',
  'OnePlus (9 Pro)',
  'OnePlus (12)',
  'Motorola (Razr+ 2023)',
  'Samsung (Galaxy A14)',
]

export const ticketingIssues = [
  'Unable to log in to my account',
  'Payment processing issues',
  'Website or app loading slowly',
  'Incorrect information on my tickets',
  'Lost or stolen tickets',
  'Event cancellation or rescheduling inquiries',
  'Trouble accessing the seating chart',
  'Refund request',
  'Technical glitches during the ticket purchase process',
  'Issues with mobile ticketing or QR code scanning',
  'Difficulty updating account information',
  'VIP or special requests not fulfilled',
  'Inquiries about event details or policies',
  'Accessibility concerns for differently-abled attendees',
  'Complaints about customer service interactions',
]
