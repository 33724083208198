import { requestWithRetry } from '../axios'

export const postTickets = async (
  seat_ids: string,
  band_uid: string,
  upgrade_uid: string,
) => {
  const response = await requestWithRetry('basket/tickets', 'post', {
    seat_ids,
    band_uid,
    upgrade_uid,
  })
  return response
}
