export const EmailIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.6743 19.6041C17.8782 20.1349 16.9534 20.4154 16 20.4154C15.0466 20.4154 14.1219 20.1349 13.3258 19.6041L0.213062 10.8621C0.140312 10.8136 0.0694375 10.763 0 10.711V25.0358C0 26.6782 1.33281 27.9816 2.94581 27.9816H29.0541C30.6965 27.9816 31.9999 26.6488 31.9999 25.0358V10.7109C31.9304 10.7631 31.8594 10.8138 31.7864 10.8623L18.6743 19.6041Z"
      fill="#9A86F7"
    />
    <path
      d="M1.25312 9.30263L14.3658 18.0448C14.8622 18.3757 15.4311 18.5411 15.9999 18.5411C16.5689 18.5411 17.1378 18.3756 17.6342 18.0448L30.7469 9.30263C31.5316 8.77981 32 7.90481 32 6.96044C32 5.33663 30.6789 4.01562 29.0552 4.01562H2.94481C1.32106 4.01569 0 5.33669 0 6.962C0 7.90481 0.4685 8.77981 1.25312 9.30263Z"
      fill="#9A86F7"
    />
  </svg>
)
