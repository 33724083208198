import { TicketsIconYellow } from '@/assets/TicketsIconYellow'
import { useState } from 'react'

type TicketImageProps = {
  src: string
}

export default function TicketImage({ src }: TicketImageProps) {
  const [loaded, setLoaded] = useState(false)

  return (
    <div className="relative w-full h-full">
      {!loaded && (
        <div
          className={`z-30 w-full h-[80svh] z-10 rounded-[35px] bg-purple flex justify-center items-center [&>svg]:relative [&>svg]:bottom-20 [&>svg]:animate-pulse`}
        >
          <TicketsIconYellow />
        </div>
      )}
      <img
        src={src}
        alt="ticket"
        className={`w-full h-full object-cover transition-opacity duration-500 ${
          loaded ? 'opacity-100' : 'opacity-0'
        }`}
        onLoad={() => setLoaded(true)}
      />
    </div>
  )
}
