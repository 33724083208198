import { formattedDate, upSeatLogoLink } from '@/utils'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Booking } from '@/types'
import HeadlineBar from '../general/HeadlineBar'
import NavLayout from '@/layouts/NavLayout'
import { useTicketsStore } from '@/context/useTicketsStore'

export const TicketReceipt = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { wallet } = useTicketsStore()
  const [currentOrder, setCurrentOrder] = useState<Booking | undefined>()

  useEffect(() => {
    if (!wallet) {
      navigate('/wallet')
    } else {
      const current = wallet.find((ticket) => ticket.order_id === id)
      setCurrentOrder(current ?? undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return currentOrder ? (
    <>
      <NavLayout>
        <HeadlineBar title={'receipt'} goBack={() => navigate('/wallet')} />
        <div className="px-5">
          <div
            className={`rounded-[20px_20px_0_0] show-card bg-purple7 relative p-3 flex flex-col gap-4 w-full mt-2`}
          >
            <div className="info-container items-center flex gap-4">
              <div
                className={`show-image rounded-md aspect-square bg-grey h-[104px]`}
              >
                <figure
                  style={{
                    backgroundImage: `url(${currentOrder.thumbnail_url.length ? currentOrder.thumbnail_url : upSeatLogoLink})`,
                  }}
                  className={`h-full rounded-[10px] bg-cover bg-top`}
                />
              </div>
              <div className="info basis-3/5 flex flex-col gap-1 justify-center text-purple">
                <div className="flex flex-col gap-1">
                  <p className="leading-none font-bold">
                    {currentOrder.event_name}
                  </p>
                  <p className="text-sm text-xs">{currentOrder.theatre_name}</p>
                </div>
                <p className="date text-sm">
                  {
                    formattedDate(currentOrder.start_time)
                      .replace(/,([^,]*, [^,]*),([^,]*)$/, '$1,$2')
                      .split(',')[0]
                  }
                  <span>
                    {
                      formattedDate(currentOrder.start_time)
                        .replace(/,([^,]*, [^,]*),([^,]*)$/, '$1,$2')
                        .split(',')[1]
                    }
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div
            className={`rounded-[0_0_20px_20px] show-card bg-white relative p-6 flex flex-col gap-4 w-full mt-[-1px]`}
          >
            <div className="info-container flex flex-col py-2 gap-4">
              <div className="flex flex w-full">
                <div className="w-full border-r border-grey2 border-solid flex flex-col gap-1 items-center">
                  <p className="uppercase text-purple font-light text-sm">{`section`}</p>
                  <p className="text-purple text-sm font-semibold">
                    {currentOrder.tickets[0].plan_name}
                  </p>
                </div>
                <div className="w-full border-r border-grey2 border-solid flex flex-col gap-1 items-center">
                  <p className="uppercase text-purple font-light text-sm">{`row`}</p>
                  <p className="text-purple text-sm font-semibold">
                    {currentOrder.tickets[0].seat_name.replace(
                      /[^a-zA-Z]/g,
                      '',
                    )}
                  </p>
                </div>
                <div className="w-full flex flex-col gap-1 items-center">
                  <p className="uppercase text-purple font-light text-sm">{`seats`}</p>
                  <p className="text-purple text-sm font-semibold">
                    {currentOrder.tickets
                      .map((seat) => seat.seat_name.replace(/\D/g, ''))
                      .map(Number)
                      .sort((a, b) => a - b)
                      .map((seatNumber, index, array) => (
                        <span key={seatNumber}>
                          {seatNumber}
                          {index !== array.length - 1 && '-'}
                        </span>
                      ))}
                  </p>
                </div>
              </div>
              <div className="border-t border-grey2 border-solid w-full flex flex-col gap-2 pt-4">
                <div className="flex justify-between">
                  <p className="text-purple text-sm font-semibold text-nowrap">{`Booking Reference`}</p>
                  <p className="text-purple text-sm text-end">
                    {currentOrder.order_id}
                  </p>
                </div>
                {/* <div className="flex justify-between">
                  <p className="text-purple text-sm font-semibold">{`Receipt Number`}</p>
                  <p className="text-purple text-sm ">{'-'}</p>
                </div> */}
              </div>
              {/* <Button variant="secondaryDark" className="w-full py-2 text-sm">
                <DownloadIcon size={16} />
                {`Download receipt`}
              </Button> */}
            </div>
          </div>
        </div>
      </NavLayout>
    </>
  ) : (
    <NavLayout>
      <HeadlineBar title={'receipt'} goBack={() => navigate('/wallet')} />
      <div className="flex flex-col gap-1 absolute top-0 h-[90vh] w-full justify-center items-center flex px-2">
        <h4 className="text-yellow text-h4 text-[1.5rem] text-center">{`Receipt not found :(`}</h4>
      </div>
    </NavLayout>
  )
}
