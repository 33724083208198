import { Section, SectionNames, sectionMap } from '@/types'

import { create } from 'zustand'

type NavigationHistoryState = {
  history: string[]
  backNav: boolean
  activeSection: Section
  pushHistory: (location: string) => void
  setBackNav: (backNav: boolean) => void
  setActiveSection: (index: number) => void
}

const useNavigationStore = create<NavigationHistoryState>((set) => ({
  history: [],
  backNav: false,
  activeSection: {
    index: 0,
    name: SectionNames.HOME,
  },
  pushHistory: (path: string) =>
    set((state: NavigationHistoryState) => ({
      history: [...state.history, path],
    })),
  setBackNav: (backNav: boolean) => set(() => ({ backNav: backNav })),
  setActiveSection: (index: number) => {
    const name: SectionNames = sectionMap[index] ?? SectionNames.HOME
    set(() => ({ activeSection: { index, name } }))
  },
}))

export default useNavigationStore
