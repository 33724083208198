import './App.scss'

import { AnimatePresence, motion } from 'framer-motion'
import {
  Navigate,
  Route,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import AuthRoutes from './components/upseat-ui/app/authRoutes'
import ErrorOverlay from './components/upseat-ui/general/ErrorOverlay'
import { GoogleOAuthProvider } from '@react-oauth/google'
import Hotjar from '@hotjar/browser'
import NetworkStatus from './components/upseat-ui/app/networkStatus'
import { OfflineWallet } from './pages/wallet/OfflineWallet'
import ProtectedRoutes from './components/upseat-ui/app/protectedRoutes'
import SentryInitialization from './helpers/sentry'
import UpAppLogo from './assets/upAppLogo.png'
import { cleanTokenFromLocalStorage } from './utils'
import { getUser } from './service/User/getUser'
import { useAppStore } from './context/useAppStore'
import { useEffect } from 'react'
import useScreenSize from './hooks/useScreenSize'
import { useTicketsStore } from './context/useTicketsStore'
import { useUserStore } from '@/context/useUserStore'
import { userExists } from './service/User/userExists'

function App() {
  const navigate = useNavigate()
  const screen = useScreenSize()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { wallet } = useTicketsStore()
  const { user, setUser } = useUserStore()
  const {
    serverError,
    setServerError,
    networkError,
    setNetworkError,
    loadingApp,
    setLoadingApp,
    cookiesConsent,
  } = useAppStore()
  const isOnline = navigator.onLine

  const getUserData = async () => {
    setLoadingApp(true)
    const token = await localStorage.getItem('access_token')

    if (navigator.onLine && !user && token) {
      const userFromToken = await getUser()
        .then((res) => {
          if (res) {
            return {
              id: res.uid,
              username: res.email.split('@')[0],
              firstname: res.first_name,
              lastname: res.last_name,
              email: res.email,
              phoneNumber: res.phone_number,
              countryCode: { acronym: 'GB', code: '+44', digits: '44' },
            }
          }
        })
        .finally(() => {
          setLoadingApp(false)
        })

      if (userFromToken) {
        setUser(userFromToken)
      } else {
        cleanTokenFromLocalStorage()

        setLoadingApp(false)
      }
    } else {
      setLoadingApp(false)
    }
  }

  // Check if user exists in the database when they click on the email link
  useEffect(() => {
    const skipVideo = searchParams.get('skipVideo') === 'true'
    const firstName = searchParams.get('firstName')
    const lastName = searchParams.get('lastName')
    const email = searchParams.get('email')

    if (email) {
      userExists(email).then(async (res: any) => {
        if (res.data.exists) {
          navigate('/login')
        } else {
          await navigate(
            `/sign-up?skipVideo=${skipVideo}&firstName=${firstName ?? ``}&lastName=${lastName ?? ``}&email=${email}`,
          )
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (serverError) {
      setTimeout(() => {
        setServerError(false)
      }, 3000)
    }
  }, [serverError, setServerError])

  useEffect(() => {
    if (networkError) {
      setTimeout(() => {
        setNetworkError(false)
      }, 3000)
    }
  }, [networkError, setNetworkError])

  useEffect(() => {
    if (!user) {
      getUserData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // Initialize Hotjar
  useEffect(() => {
    if (cookiesConsent) {
      Hotjar.init(parseInt(process.env.REACT_APP_HOTJAR_ID as string), 6, {
        debug: true,
      })
      console.log('Hotjar Initialized!')
    }
  }, [cookiesConsent])

  useEffect(() => {
    if (cookiesConsent) {
      Hotjar.stateChange(location.pathname)
    }
  }, [cookiesConsent, location])

  return (
    <div data-testid="upseat">
      <NetworkStatus />

      {serverError && (
        <ErrorOverlay
          errorType="server"
          message="Server Error. Please try again"
          onClose={() => setServerError(false)}
        />
      )}
      {networkError && (
        <ErrorOverlay
          errorType="network"
          message="It looks like you're offline :("
          onClose={() => setNetworkError(false)}
        />
      )}

      {!loadingApp ? (
        <div
          className={`upseat w-screen h-[100svh] bg-purple overflow-hidden flex`}
        >
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string}
          >
            <AnimatePresence>
              {!isOnline && wallet?.length ? (
                <>
                  <Route path="/offwallet" element={<OfflineWallet />} />
                  <Route
                    path="*"
                    element={<Navigate to="/offwallet" replace />}
                  />
                </>
              ) : !user ? (
                <AuthRoutes />
              ) : (
                <>
                  <SentryInitialization user={user} />
                  <ProtectedRoutes user={user} />
                </>
              )}
            </AnimatePresence>
          </GoogleOAuthProvider>
        </div>
      ) : (
        <div className="bg-purple gap-4 w-[100vw] h-[100svh] flex flex-col justify-center items-center text-white">
          <motion.img
            src={UpAppLogo}
            alt="upSeat-logo"
            className={`${screen.width > 1024 ? 'w-[15vw]' : 'w-[50vw]'}`}
            initial={{ scale: 0.5 }}
            animate={{ rotate: 0, scale: 1 }}
            transition={{
              type: 'spring',
              stiffness: 260,
              damping: 20,
            }}
          />
        </div>
      )}
    </div>
  )
}

export default App
