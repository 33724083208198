import {
  forgotPasswordFormSchema,
  forgotPasswordFormSchemaType,
} from '../../lib/validation-schemas/forgotPasswordFormSchema'
import { useEffect, useState } from 'react'

import { AllScreenBlur } from '@/components/upseat-ui/slides/AllScreenBlur'
import { Button } from '@/components/ui/button'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import { Input } from '@/components/ui/input'
import LoadingIndicator from '@/components/upseat-ui/seatMap/LoadingIndicator'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { sendResetPasswordLink } from '@/service/Login/sendResetPasswordLink'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

export const ForgotPassword = () => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const [email, setEmail] = useState<string | null>()
  const [loadingForgotPassword, setLoadingForgotPassword] =
    useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [resetPasswordLinkSent, setResetPasswordLinkSent] =
    useState<boolean>(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<forgotPasswordFormSchemaType>({
    resolver: zodResolver(forgotPasswordFormSchema),
  })

  useEffect(() => {
    if (resetPasswordLinkSent) {
      setTimeout(() => {
        callRouteWithDirection('/wallet', true, 1)
      }, 3000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPasswordLinkSent])

  const sendLink = async () => {
    setLoadingForgotPassword(true)
    await sendResetPasswordLink(email as string)
      .then(() => {
        setResetPasswordLinkSent(true)
      })
      .catch((err: any) => {
        console.log('Send password reset link Error:', err.response.data.error)
        setError(true)
      })
      .finally(() => {
        setLoadingForgotPassword(false)
      })
  }

  return (
    <DefaultLayout>
      {resetPasswordLinkSent && (
        <AllScreenBlur dimApp close={() => !resetPasswordLinkSent}>
          <div className="w-full bg-green1 z-[999] p-4 rounded-[10px]">
            <h1>{`Reset Password link sent!`}</h1>
          </div>
        </AllScreenBlur>
      )}
      <RouteTransition>
        <div className="flex flex-col justify-start gap-4">
          <HeadlineBar
            title={`forgot my password`}
            goBack={() => callRouteWithDirection('/wallet', true, 1)}
          />
        </div>

        <form
          className="bg-white overflow-scroll flex flex-col justify-between gap-4 text-purple h-[90svh] p-4 py-6"
          autoComplete={'off'}
        >
          <div className="flex flex-col gap-2">
            <p className="px-2 text-pretty mb-2">{`Enter your email and we’ll send you a link to reset your password.`}</p>

            <Input
              className="py-4"
              placeholder="Email address"
              type="email"
              value={email ?? ''}
              onChange={(e) => setEmail(e.target.value)}
            />

            {/* {errors.newPassword && (
                    <p className="text-[12px] text-pretty pl-2 text-red">
                      {errors.newPassword.message}
                    </p>
                  )} */}

            {/* <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="flex flex-col relative">
                  <Input
                    className={`${
                      errors.confirmPassword?.message && 'border-red'
                    } py-4`}
                    type={showConfirmationPassword ? 'text' : 'password'}
                    placeholder="Confirm New Password"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                      setError(false)
                    }}
                  />
                  <label
                    onClick={() =>
                      setShowConfirmationPassword(!showConfirmationPassword)
                    }
                    className="absolute top-4 right-4 bg-gray-300 hover:bg-gray-400 rounded text-sm text-gray-600 font-mono cursor-pointer"
                  >
                    {showConfirmationPassword ? (
                      <Eye color="grey" />
                    ) : (
                      <EyeOff color="grey" />
                    )}
                  </label>

                  {errors.confirmPassword && (
                    <p className="text-[12px] text-pretty pl-2 text-red">
                      {errors.confirmPassword.message}
                    </p>
                  )}
                </div>
              )}
            /> */}
            {error && (
              <p className="text-red text-center text-[12px] text-balance relative top-[-5px]">
                {`User not found. Please try again.`}
              </p>
            )}
          </div>

          <div className="py-4">
            {!loadingForgotPassword ? (
              <Button
                variant="primaryDark"
                className="w-full"
                type="submit"
                disabled={!email}
                onClick={sendLink}
              >{`Send reset password link`}</Button>
            ) : (
              <div className="py-4">
                <LoadingIndicator dark />
              </div>
            )}
          </div>
        </form>
      </RouteTransition>
    </DefaultLayout>
  )
}
