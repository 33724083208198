import CheckoutForm from './CheckoutForm'
import { Elements } from '@stripe/react-stripe-js'
import LoadingIndicator from '@/components/upseat-ui/seatMap/LoadingIndicator'
import { loadStripe } from '@stripe/stripe-js'

interface StripeProps {
  clientSecret?: string
  confirmTickets: () => Promise<void>
}

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
)

export const Stripe = ({ clientSecret, confirmTickets }: StripeProps) => {
  const appearance = {
    theme: 'flat',
    variables: {
      colorPrimary: '#19162c',
      colorBackground: '#f4f2ff',
      colorText: '#19162c',
    },
  }

  const options = {
    clientSecret,
    appearance,
    locale: 'en',
  } as any

  return (
    <>
      {clientSecret ? (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm confirmTickets={confirmTickets} />
        </Elements>
      ) : (
        <div className="flex items-center justify-center h-[20vh] py-4">
          <LoadingIndicator dark />
        </div>
      )}
    </>
  )
}
