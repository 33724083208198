import { Button } from '@/components/ui/button'
import NavLayout from '@/layouts/NavLayout'
import UpAppLogo from '@/assets/upAppLogo.png'
import { motion } from 'framer-motion'
import useScreenSize from '@/hooks/useScreenSize'

export default function PaymentSuccess() {
  const screen = useScreenSize()

  return (
    <NavLayout>
      <main className="w-full h-[100svh] flex items-center justify-center p-8">
        <div className="flex flex-col items-center justify-center">
          <motion.img
            src={UpAppLogo}
            alt="upSeat-logo"
            className={`${screen.width > 1024 ? 'w-[5vw]' : 'w-[25vw]'}`}
            initial={{ scale: 0 }}
            animate={{ rotate: 0, scale: 1 }}
            transition={{
              type: 'spring',
              stiffness: 260,
              damping: 20,
            }}
          />
          <h5 className="text-h5 text-white text-center text-pretty">
            {'Your ticket has been successfully upgraded!'}
          </h5>
          <Button
            variant="secondaryYellow"
            data-testid="sign-up-email-button"
            className="py-2 px-6 mt-8 text-sm"
            onClick={() => window.location.reload()}
          >
            {`Back to My Wallet`}
          </Button>
        </div>
      </main>
    </NavLayout>
  )
}
