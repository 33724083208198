import React, { ReactNode } from 'react'

import { UpSeatTiles } from '@/assets/upseat-tiles'
import useScreenSize from '@/hooks/useScreenSize'

type NavLayoutProps = {
  children: ReactNode
  overflowHidden?: boolean
  plain?: boolean
}

const NavLayout: React.FC<NavLayoutProps> = ({
  children,
  overflowHidden,
  plain,
}) => {
  const screenSize = useScreenSize()
  return (
    <div
      id="nav-layout"
      className={`app-view bg-purple
      ${screenSize.width > 1024 && 'pl-[33vw] pr-[33vw] overflow-hidden flex flex-col justify-between h-auto'}
      ${overflowHidden && 'overflow-hidden'}
      `}
    >
      {!plain && (
        <figure className="fixed z-0 right-0 top-0 opacity-70">
          <UpSeatTiles />
        </figure>
      )}
      <main className={`relative`}>{children}</main>
    </div>
  )
}
// h-lvh
export default NavLayout
