import { z } from 'zod'

export const contactUsFormSchema = z
  .object({
    firstName: z.string().min(1, { message: 'First Name is required' }),
    lastName: z.string().min(1, { message: 'Last Name is required' }),
    email: z.string().min(1, { message: 'Email is required' }),
    device: z.string().min(1, { message: 'Device is required' }),
    issue: z.string().min(1, { message: 'Issue is required' }),
    issueDescription: z
      .string()
      .min(1, { message: 'Issue Description is required' }),
  })
  .refine((data) => data.email.includes('@'), {
    message: 'Invalid email address. Please try again.',
    path: ['email'],
  })

export type contactUsFormSchemaType = z.infer<typeof contactUsFormSchema>
