import { useRef, useState } from 'react'

import { ArrowRightLongWhite } from '@/assets/ArrowRightLongWhite'
import { Button } from '@/components/ui/button'
import { CountryCode } from '@/types'
import { SignUpForm } from '@/components/upseat-ui/account/forms/SignUpForm'
import { useSearchParams } from 'react-router-dom'
import { useUserStore } from '@/context/useUserStore'

export const SignUpPage = () => {
  const { signUpForm, setSignUpForm } = useUserStore()
  const [searchParams] = useSearchParams()
  const [phoneAlertForm, setPhoneAlertForm] = useState<boolean>(false)
  const [countryCode, setCountryCode] = useState<CountryCode>({
    code: '+44',
    acronym: 'GB',
    digits: '44',
  })
  const [videoOn, setVideoOn] = useState<boolean>(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const skipVideo = searchParams.get('skipVideo') === 'true'

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  return (
    <>
      {videoOn && !skipVideo ? (
        <div className="bg-purple h-full w-full">
          <Button
            className="flex py-1 px-4 gap-1 z-50 bg-purple2 text-white items-center justify-center absolute capitalize top-2 right-2 cursor-pointer"
            onClick={() => setVideoOn(false)}
            variant={'textOnly'}
            size={'small'}
            data-testid="skip-tutorial-button"
            id="skip-tutorial-button"
          >
            {`skip`} <ArrowRightLongWhite />
          </Button>
          <div className="flex overflow-hidden w-[100vw] h-full">
            <video
              autoPlay
              muted={true}
              playsInline={true}
              ref={videoRef}
              className="w-[100vw]"
              onClick={togglePlayPause}
              onEnded={() => setVideoOn(false)}
              onError={() => setVideoOn(false)}
            >
              <source
                src="https://res.cloudinary.com/dzqafjknm/video/upload/v1722968129/UpSeat_Demo_Video_rbfx7c.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      ) : (
        <SignUpForm
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          setSignUpForm={setSignUpForm}
          setPhoneAlertForm={setPhoneAlertForm}
        />
      )}
    </>
  )
}
