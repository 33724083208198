import * as React from 'react'

import { cn } from '@/utils'

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  variant?: 'default' | 'dark'
}
export type TextareaProps = React.InputHTMLAttributes<HTMLTextAreaElement> & {
  variant?: 'default' | 'dark'
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, variant, ...props }, ref) => {
    return (
      <input
        type={type}
        autoComplete="off"
        className={cn(
          'flex w-full rounded-full px-6 py-5 text-purple file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-purple placeholder:font-light focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:border-purple4 disabled:cursor-not-allowed disabled:opacity-50',
          variant === 'dark'
            ? 'bg-purple text-white border-purple3 border-[1.5px] placeholder:text-purple5 focus-visible:shadow-[0px_0px_4px_0px_var(--purple-04,#9A86F7)] focus-visible:bg-purple1 focus-visible:text-white'
            : 'bg-purple7 border-purple7 text-purple focus-visible:outline-purple4',
          className,
        )}
        ref={ref}
        {...props}
      />
    )
  },
)
Input.displayName = 'Input'

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, variant, type, ...props }, ref) => {
    return (
      <textarea
        autoComplete="off"
        className={cn(
          'flex w-full rounded-full bg-purple7 border border-purple7 px-6 py-5 text-purple file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-purple placeholder:font-light focus-visible:outline-none focus-visible:border-purple4 focus-visible:bg-white focus-visible:text-purple disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
        style={{ verticalAlign: 'top' }}
        ref={ref}
        {...props}
      />
    )
  },
)
Input.displayName = 'Textarea'

export { Input, Textarea }
