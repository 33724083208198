import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
  changePasswordFormSchema,
  changePasswordFormSchemaType,
} from '@/lib/validation-schemas/changePasswordFormSchema'

import { Button } from '@/components/ui/button'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import { Input } from '@/components/ui/input'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { zodResolver } from '@hookform/resolvers/zod'

interface ChangePasswordProps {
  setChangePasswordPage: (value: boolean) => void
}

export const ChangePassword = ({
  setChangePasswordPage,
}: ChangePasswordProps) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<changePasswordFormSchemaType>({
    resolver: zodResolver(changePasswordFormSchema),
  })

  const onSubmit: SubmitHandler<changePasswordFormSchemaType> = (data) => {
    console.log('DATA SENT ->', data)
  }

  return (
    <DefaultLayout>
      <RouteTransition>
        <div className="flex flex-col justify-start gap-4">
          <HeadlineBar
            title={`change password`}
            goBack={() => setChangePasswordPage(false)}
          />
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white overflow-scroll flex flex-col gap-4 text-purple h-[90svh] p-4 py-6"
          autoComplete={'off'}
        >
          <div className="flex flex-col gap-2">
            <Controller
              name="currentPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="flex flex-col">
                  <Input
                    className={`${
                      errors.currentPassword?.message && 'border-red'
                    } py-4`}
                    type="password"
                    placeholder="Enter your current password"
                    {...field}
                  />

                  {errors.currentPassword && (
                    <p className="text-[12px] text-pretty pl-2 text-red">
                      {errors.currentPassword.message}
                    </p>
                  )}
                </div>
              )}
            />

            <Controller
              name="newPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="flex flex-col">
                  <Input
                    className={`${
                      errors.newPassword?.message && 'border-red'
                    } py-4`}
                    type="password"
                    placeholder="New Password"
                    {...field}
                  />

                  {errors.newPassword && (
                    <p className="text-[12px] text-pretty pl-2 text-red">
                      {errors.newPassword.message}
                    </p>
                  )}

                  <p
                    className="relative my-2 text-pretty text-purple3 text-sm font-light px-2"
                    onClick={() => setChangePasswordPage(false)}
                  >{`Password must be at least 8 characters long with at least one capital letter and symbol`}</p>
                </div>
              )}
            />

            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="flex flex-col">
                  <Input
                    className={`${
                      errors.confirmPassword?.message && 'border-red'
                    } py-4`}
                    type="password"
                    placeholder="Confirm New Password"
                    {...field}
                  />

                  {errors.confirmPassword && (
                    <p className="text-[12px] text-pretty pl-2 text-red">
                      {errors.confirmPassword.message}
                    </p>
                  )}
                </div>
              )}
            />
          </div>

          <Button
            variant="primaryDark"
            // disabled={
            //   newFirstName === user?.firstname &&
            //   newLastName === user?.lastname &&
            //   newEmail === user?.email &&
            //   newPhoneNumber === user?.phone &&
            //   newCountryCode.acronym === user?.countryCode
            // }
            className="w-full font-normal py-4 mt-4"
            type="submit"
          >{`Change password`}</Button>
          <p
            className="relative my-2 text-center text-purple3"
            onClick={() => setChangePasswordPage(false)}
          >{`Cancel`}</p>
        </form>
      </RouteTransition>
    </DefaultLayout>
  )
}
