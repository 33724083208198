export const Circle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="358"
    height="463"
    viewBox="0 0 358 463"
    fill="none"
  >
    <g filter="url(#filter0_d)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 18C0 8.05888 8.05888 0 18 0C27.9411 0 36 8.05888 36 18V283C36 292.941 44.0589 301 54 301H304C313.941 301 322 292.941 322 283V18C322 8.05888 330.059 0 340 0C349.941 0 358 8.05888 358 18V445C358 454.941 349.941 463 340 463H322.017C322.008 463 322 462.992 322 462.983C322 462.973 321.992 462.965 321.982 462.966C321.601 462.988 321.217 463 320.831 463H37.1689C36.7825 463 36.3989 462.988 36.0182 462.966C36.0083 462.965 36 462.973 36 462.983C36 462.992 35.9923 463 35.9828 463H18.9655C8.49115 463 0 454.509 0 444.034V18Z"
        fill="white"
        stroke="lightgrey"
        strokeWidth="1"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="358"
        height="463"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
