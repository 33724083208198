import { fetchAllBookingsFromDB, getUniqueId } from '@/utils'
import { useEffect, useState } from 'react'

import { Booking } from '@/types'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import NavLayout from '@/layouts/NavLayout'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { TicketCardBackFace } from '@/components/upseat-ui/tickets/TicketCardBackFace'
import { UpSeatLogoLightSmall } from '@/assets/upseat_light_small'

export const OfflineWallet = () => {
  const [storedBookings, setStoredBookings] = useState<Booking[]>([])

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const bookingsFromDB = await fetchAllBookingsFromDB()
        setStoredBookings(bookingsFromDB)
      } catch (error) {
        console.error('Error fetching bookings from indexedDB:', error)
      }
    }

    fetchBookings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <NavLayout plain>
      <RouteTransition>
        <div className="w-full flex flex-col items-center justify-center">
          <div className="w-full">
            <HeadlineBar
              title={
                <div className="flex items-center">
                  <figure className="[&>svg]:w-auto [&>svg]:h-[2rem] [&>svg]:relative [&>svg]:top-[2px] pr-[2px]">
                    <UpSeatLogoLightSmall />
                  </figure>
                  {`seat offline wallet`}
                </div>
              }
            />
          </div>

          <div className="[&>div]:h-[100vh] [&>div]:w-[80vw] pt-4">
            {storedBookings.map((bookingOrder: Booking) => (
              <TicketCardBackFace
                key={getUniqueId()}
                bookingTickets={bookingOrder}
              />
            ))}
          </div>
        </div>
      </RouteTransition>
    </NavLayout>
  )
}
