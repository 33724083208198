/* eslint-disable max-lines */
import { Plus, Star } from 'lucide-react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { isCurrentSeat, selectSeat } from '../..'
import { useEffect, useState } from 'react'

import { Circle } from '@/assets/mapSeats/Arts/Circle'
import { CircleCentral } from './CircleCentral'
import { PopModal } from '../../../modals/PopModal'
import React from 'react'
import { Seat } from '@/types'
import { StallsCentral } from './StallsCentral'
import { motion } from 'framer-motion'
import useEventStore from '@/context/useEventStore'
import useExistingBookingStore from '@/context/useExistingBookingStore'
import useScreenSize from '@/hooks/useScreenSize'
import { useSeatMapFilterStore } from '@/context/useSeatMapFilterStore'

export const ArtsSeatMap = () => {
  const { discountRange, setDiscountRange } = useSeatMapFilterStore()
  const { booking } = useExistingBookingStore()
  const { mapSeats, priceBands, selectedSeats, setSelectedSeats } =
    useEventStore()
  const [seatAsideWarning, setSeatAsideWarning] = useState<boolean>(false)
  const screenSize = useScreenSize()

  useEffect(() => {
    setDiscountRange([0, priceBands.length - 1])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const mapSeatsByRow = (mapSeats: Seat[], location?: string) => {
    const seatsByRow = {} as Record<string, Seat[]>

    mapSeats
      .filter((seat) => seat.location === location)
      .forEach((seat: Seat) => {
        const { row } = seat
        if (!seatsByRow[row]) {
          seatsByRow[row] = []
        }

        seatsByRow[row]?.push(seat)
      })

    // Convert seatsByRow object to an array
    const result = []

    // Convert seatsByRow object to an array of objects with 'row' and 'seats' properties
    for (const row in seatsByRow) {
      if (seatsByRow.hasOwnProperty(row)) {
        result.push({ row, seats: seatsByRow[row] })
      }
    }
    return result
  }

  const filterResults = (tier: number, discountRange: number[]) => {
    // check if the tier is within the discount range
    if (tier - 1 >= discountRange[0] && tier - 1 <= discountRange[1]) {
      return
    } else {
      return 'opacity-[.2] pointer-events-none'
    }
  }

  return (
    <div className="flex justify-center mt-2 mb-[10vh] h-[80vh]">
      <TransformWrapper
        disabled // disable zooming
        initialScale={1}
        minScale={0.8}
        limitToBounds
        disablePadding
        wheel={{
          disabled: true,
          wheelDisabled: true,
          touchPadDisabled: true,
        }}
        doubleClick={{
          disabled: true,
        }}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <>
            <TransformComponent
              wrapperStyle={{
                width: '100%',
                height: '85vh',
              }}
            >
              <div
                className={`h-screen flex flex-row flex-wrap content-start relative  ${screenSize.width > 1280 ? 'w-[35vw]' : 'w-screen'}`}
              >
                <div className="seat-map rounded-containers h-fit w-full px-1 pb-8 flex flex-col items-center">
                  <span className="relative block h-[20px] mx-[7rem] w-[80%] text-[12px] bg-grey2 rounded-[0_0_10px_10px] mb-2 uppercase flex justify-center items-center">
                    {`stage`}
                  </span>
                  <div className="absolute w-[stretch]">
                    <span className="absolute w-[inherit] border border-b border-grey2 top-0 mx-4" />
                  </div>

                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                    className="relative flex justify-center"
                  >
                    <div className="absolute border border-grey2 pb-8 pt-0 rounded-[10px] flex flex-col items-center w-[305px] h-[25rem]"></div>
                    <div className="absolute pb-8 pt-0 rounded-[10px] flex flex-col items-center w-[305px] z-[1]">
                      <p className="text-grey w-full text-center pt-2 uppercase text-[11px]">{`Stalls`}</p>
                      <div className="flex gap-3">
                        <StallsCentral
                          booking={booking}
                          discountRange={discountRange}
                          selectedSeats={selectedSeats}
                          setSelectedSeats={setSelectedSeats}
                          setSeatAsideWarning={setSeatAsideWarning}
                          mapSeatsByRow={mapSeatsByRow}
                        />
                      </div>
                    </div>

                    <div className="absolute top-[3rem]">
                      <div className="flex justify-center relative [&>svg]:rounded-[17px]">
                        <Circle />
                        <div>
                          <div className="seat-map absolute h-fit top-[16px] left-[5px] rounded-containers">
                            {mapSeats && (
                              <div className="flex gap-1">
                                {mapSeatsByRow(
                                  mapSeats,
                                  'Circle Slips Left',
                                ).map((row) => {
                                  return (
                                    <div
                                      className="flex items-center w-full mb-2"
                                      key={row.row}
                                    >
                                      <div className="w-full relative flex flex-col justify-center gap-1">
                                        {row.seats
                                          .filter(
                                            (seat) =>
                                              parseInt(seat.number) <= 7,
                                          )
                                          .map((seat, index) => {
                                            return (
                                              <div
                                                key={seat.seat_id}
                                                onClick={() =>
                                                  selectSeat(
                                                    seat,
                                                    selectedSeats,
                                                    setSelectedSeats,
                                                    setSeatAsideWarning,
                                                  )
                                                }
                                                style={{
                                                  width: `11px`,
                                                  height: `11px`,
                                                }}
                                                className={`seat flex items-center justify-center ${row.row + seat.number}
                      ${selectedSeats.includes(seat) && 'selected-seat'}
                      ${
                        isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) && 'current-seat pointer-events-none'
                      }
                      ${
                        !selectedSeats.includes(seat) &&
                        !isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) &&
                        filterResults(seat.tier, discountRange)
                      }
                      ${
                        !selectedSeats.includes(seat) &&
                        !isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) &&
                        booking?.tickets.length === selectedSeats.length &&
                        'opacity-[.2] pointer-events-none'
                      }
                      `}
                                              >
                                                <motion.div
                                                  initial={{ scale: 0 }}
                                                  animate={{
                                                    scale: isCurrentSeat(
                                                      booking,
                                                      seat.row +
                                                        seat.number.toString(),
                                                      seat.location,
                                                    )
                                                      ? [1, 2, 2, 1, 1]
                                                      : 1,
                                                    rotate: isCurrentSeat(
                                                      booking,
                                                      seat.row +
                                                        seat.number.toString(),
                                                      seat.location,
                                                    )
                                                      ? [0, 0, -270, -270, 0]
                                                      : 0,
                                                    borderRadius: isCurrentSeat(
                                                      booking,
                                                      seat.row +
                                                        seat.number.toString(),
                                                      seat.location,
                                                    )
                                                      ? [
                                                          '20%',
                                                          '20%',
                                                          '50%',
                                                          '50%',
                                                          '20%',
                                                        ]
                                                      : '0%',
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      backgroundColor: seat.tier
                                                        ? 'var(--tier' +
                                                          seat.tier +
                                                          ')'
                                                        : 'grey',
                                                    }}
                                                    className={`relative flex items-center justify-center h-[11px] w-[11px] block rounded-[4px]
                          ${selectedSeats.includes(seat) && `!bg-blue`}
                                  ${
                                    isCurrentSeat(
                                      booking,
                                      seat.row + seat.number.toString(),
                                      seat.location,
                                    ) &&
                                    `!bg-[white] border-[2px] border-[black]`
                                  }`}
                                                  >
                                                    {isCurrentSeat(
                                                      booking,
                                                      seat.row +
                                                        seat.number.toString(),
                                                      seat.location,
                                                    ) && (
                                                      <Plus
                                                        color="black"
                                                        style={{
                                                          rotate: '45deg',
                                                          position: 'absolute',
                                                        }}
                                                        width={13}
                                                        height={13}
                                                      />
                                                    )}
                                                    {selectedSeats.includes(
                                                      seat,
                                                    ) && (
                                                      <Star
                                                        color="white"
                                                        fill="white"
                                                        size={7}
                                                      />
                                                    )}
                                                  </span>
                                                </motion.div>
                                              </div>
                                            )
                                          })}
                                      </div>
                                    </div>
                                  )
                                })}
                                {mapSeatsByRow(
                                  mapSeats,
                                  'Circle Slips Left',
                                ).map((row) => {
                                  return (
                                    <div
                                      className="flex items-center w-full mb-2"
                                      key={row.row}
                                    >
                                      <div className="w-full relative flex flex-col justify-center gap-1">
                                        {row.seats
                                          .filter(
                                            (seat) => parseInt(seat.number) > 7,
                                          )
                                          .map((seat, index) => {
                                            return (
                                              <div
                                                key={seat.seat_id}
                                                onClick={() =>
                                                  selectSeat(
                                                    seat,
                                                    selectedSeats,
                                                    setSelectedSeats,
                                                    setSeatAsideWarning,
                                                  )
                                                }
                                                style={{
                                                  width: `11px`,
                                                  height: `11px`,
                                                }}
                                                className={`seat flex items-center justify-center ${row.row + seat.number}
                      ${selectedSeats.includes(seat) && 'selected-seat'}
                      ${
                        isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) && 'current-seat pointer-events-none'
                      }
                      ${
                        !selectedSeats.includes(seat) &&
                        !isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) &&
                        filterResults(seat.tier, discountRange)
                      }
                      ${
                        !selectedSeats.includes(seat) &&
                        !isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) &&
                        booking?.tickets.length === selectedSeats.length &&
                        'opacity-[.2] pointer-events-none'
                      }
                      `}
                                              >
                                                <motion.div
                                                  initial={{ scale: 0 }}
                                                  animate={{
                                                    scale: isCurrentSeat(
                                                      booking,
                                                      seat.row +
                                                        seat.number.toString(),
                                                      seat.location,
                                                    )
                                                      ? [1, 2, 2, 1, 1]
                                                      : 1,
                                                    rotate: isCurrentSeat(
                                                      booking,
                                                      seat.row +
                                                        seat.number.toString(),
                                                      seat.location,
                                                    )
                                                      ? [0, 0, -270, -270, 0]
                                                      : 0,
                                                    borderRadius: isCurrentSeat(
                                                      booking,
                                                      seat.row +
                                                        seat.number.toString(),
                                                      seat.location,
                                                    )
                                                      ? [
                                                          '20%',
                                                          '20%',
                                                          '50%',
                                                          '50%',
                                                          '20%',
                                                        ]
                                                      : '0%',
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      backgroundColor: seat.tier
                                                        ? 'var(--tier' +
                                                          seat.tier +
                                                          ')'
                                                        : 'grey',
                                                    }}
                                                    className={`relative flex items-center justify-center h-[11px] w-[11px] block rounded-[4px]
                          ${selectedSeats.includes(seat) && `!bg-blue`}
                                  ${
                                    isCurrentSeat(
                                      booking,
                                      seat.row + seat.number.toString(),
                                      seat.location,
                                    ) &&
                                    `!bg-[white] border-[2px] border-[black]`
                                  }`}
                                                  >
                                                    {isCurrentSeat(
                                                      booking,
                                                      seat.row +
                                                        seat.number.toString(),
                                                      seat.location,
                                                    ) && (
                                                      <Plus
                                                        color="black"
                                                        style={{
                                                          rotate: '45deg',
                                                          position: 'absolute',
                                                        }}
                                                        width={13}
                                                        height={13}
                                                      />
                                                    )}
                                                    {selectedSeats.includes(
                                                      seat,
                                                    ) && (
                                                      <Star
                                                        color="white"
                                                        fill="white"
                                                        size={7}
                                                      />
                                                    )}
                                                  </span>
                                                </motion.div>
                                              </div>
                                            )
                                          })}
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="seat-map absolute h-fit top-[16px] right-[5px] rounded-containers">
                          {mapSeats && (
                            <div className="flex gap-1">
                              {mapSeatsByRow(
                                mapSeats,
                                'Circle Slips Right',
                              ).map((row) => {
                                return (
                                  <div
                                    className="flex items-center w-full mb-2"
                                    key={row.row}
                                  >
                                    <div className="w-full relative flex flex-col justify-center gap-1">
                                      {row.seats
                                        .filter(
                                          (seat) => parseInt(seat.number) <= 7,
                                        )
                                        .map((seat, index) => {
                                          return (
                                            <div
                                              key={seat.seat_id}
                                              onClick={() =>
                                                selectSeat(
                                                  seat,
                                                  selectedSeats,
                                                  setSelectedSeats,
                                                  setSeatAsideWarning,
                                                )
                                              }
                                              style={{
                                                width: `11px`,
                                                height: `11px`,
                                              }}
                                              className={`seat flex items-center justify-center ${row.row + seat.number}
                      ${selectedSeats.includes(seat) && 'selected-seat'}
                      ${
                        isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) && 'current-seat pointer-events-none'
                      }
                      ${
                        !selectedSeats.includes(seat) &&
                        !isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) &&
                        filterResults(seat.tier, discountRange)
                      }
                      ${
                        !selectedSeats.includes(seat) &&
                        !isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) &&
                        booking?.tickets.length === selectedSeats.length &&
                        'opacity-[.2] pointer-events-none'
                      }
                      `}
                                            >
                                              <motion.div
                                                initial={{ scale: 0 }}
                                                animate={{
                                                  scale: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [1, 2, 2, 1, 1]
                                                    : 1,
                                                  rotate: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [0, 0, -270, -270, 0]
                                                    : 0,
                                                  borderRadius: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [
                                                        '20%',
                                                        '20%',
                                                        '50%',
                                                        '50%',
                                                        '20%',
                                                      ]
                                                    : '0%',
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    backgroundColor: seat.tier
                                                      ? 'var(--tier' +
                                                        seat.tier +
                                                        ')'
                                                      : 'grey',
                                                  }}
                                                  className={`relative flex items-center justify-center h-[11px] w-[11px] block rounded-[4px]
                          ${selectedSeats.includes(seat) && `!bg-blue`}
                                  ${
                                    isCurrentSeat(
                                      booking,
                                      seat.row + seat.number.toString(),
                                      seat.location,
                                    ) &&
                                    `!bg-[white] border-[2px] border-[black]`
                                  }`}
                                                >
                                                  {isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  ) && (
                                                    <Plus
                                                      color="black"
                                                      style={{
                                                        rotate: '45deg',
                                                        position: 'absolute',
                                                      }}
                                                      width={13}
                                                      height={13}
                                                    />
                                                  )}
                                                  {selectedSeats.includes(
                                                    seat,
                                                  ) && (
                                                    <Star
                                                      color="white"
                                                      fill="white"
                                                      size={7}
                                                    />
                                                  )}
                                                </span>
                                              </motion.div>
                                            </div>
                                          )
                                        })}
                                    </div>
                                  </div>
                                )
                              })}
                              {mapSeatsByRow(
                                mapSeats,
                                'Circle Slips Right',
                              ).map((row) => {
                                return (
                                  <div
                                    className="flex items-center w-full mb-2"
                                    key={row.row}
                                  >
                                    <div className="w-full relative flex flex-col justify-center gap-1">
                                      {row.seats
                                        .filter(
                                          (seat) => parseInt(seat.number) <= 7,
                                        )
                                        .map((seat, index) => {
                                          return (
                                            <div
                                              key={seat.seat_id}
                                              onClick={() =>
                                                selectSeat(
                                                  seat,
                                                  selectedSeats,
                                                  setSelectedSeats,
                                                  setSeatAsideWarning,
                                                )
                                              }
                                              style={{
                                                width: `11px`,
                                                height: `11px`,
                                              }}
                                              className={`seat flex items-center justify-center ${row.row + seat.number}
                      ${selectedSeats.includes(seat) && 'selected-seat'}
                      ${
                        isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) && 'current-seat pointer-events-none'
                      }
                      ${
                        !selectedSeats.includes(seat) &&
                        !isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) &&
                        filterResults(seat.tier, discountRange)
                      }
                      ${
                        !selectedSeats.includes(seat) &&
                        !isCurrentSeat(
                          booking,
                          seat.row + seat.number.toString(),
                          seat.location,
                        ) &&
                        booking?.tickets.length === selectedSeats.length &&
                        'opacity-[.2] pointer-events-none'
                      }
                      `}
                                            >
                                              <motion.div
                                                initial={{ scale: 0 }}
                                                animate={{
                                                  scale: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [1, 2, 2, 1, 1]
                                                    : 1,
                                                  rotate: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [0, 0, -270, -270, 0]
                                                    : 0,
                                                  borderRadius: isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  )
                                                    ? [
                                                        '20%',
                                                        '20%',
                                                        '50%',
                                                        '50%',
                                                        '20%',
                                                      ]
                                                    : '0%',
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    backgroundColor: seat.tier
                                                      ? 'var(--tier' +
                                                        seat.tier +
                                                        ')'
                                                      : 'grey',
                                                  }}
                                                  className={`relative flex items-center justify-center h-[11px] w-[11px] block rounded-[4px]
                          ${selectedSeats.includes(seat) && `!bg-blue`}
                                  ${
                                    isCurrentSeat(
                                      booking,
                                      seat.row + seat.number.toString(),
                                      seat.location,
                                    ) &&
                                    `!bg-[white] border-[2px] border-[black]`
                                  }`}
                                                >
                                                  {isCurrentSeat(
                                                    booking,
                                                    seat.row +
                                                      seat.number.toString(),
                                                    seat.location,
                                                  ) && (
                                                    <Plus
                                                      color="black"
                                                      style={{
                                                        rotate: '45deg',
                                                        position: 'absolute',
                                                      }}
                                                      width={13}
                                                      height={13}
                                                    />
                                                  )}
                                                  {selectedSeats.includes(
                                                    seat,
                                                  ) && (
                                                    <Star
                                                      color="white"
                                                      fill="white"
                                                      size={7}
                                                    />
                                                  )}
                                                </span>
                                              </motion.div>
                                            </div>
                                          )
                                        })}
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          )}
                        </div>

                        <CircleCentral
                          booking={booking}
                          discountRange={discountRange}
                          selectedSeats={selectedSeats}
                          setSelectedSeats={setSelectedSeats}
                          setSeatAsideWarning={setSeatAsideWarning}
                          mapSeatsByRow={mapSeatsByRow}
                        />
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>

      <PopModal
        title={''}
        description={`Please select a seat next to the already selected seat`}
        buttonText={`Got it, let’s go`}
        open={seatAsideWarning}
        onClick={() => setSeatAsideWarning(false)}
      />
    </div>
  )
}
