import { requestWithRetry } from '../axios'

export const deleteUser = async () => {
  try {
    return await requestWithRetry('upauth/details', 'patch', {
      is_active: 'False',
    })
  } catch (error) {
    // Handle error as per your requirement
    console.log('deleteUser Error:', error)
  }
}
