import { AnimatePresence, motion } from 'framer-motion'
import { InfoIcon, Plus, SearchIcon } from 'lucide-react'

interface SearchInputProps {
  content: React.ReactNode
  modalOpen: boolean
}

export const Modal = ({ content, modalOpen }: SearchInputProps) => {
  return (
    <AnimatePresence>
      {modalOpen && (
        <div className="flex flex-col">
          <motion.div
            className="flex items-end justify-center sm:block sm:p-0"
            initial={{ y: 0, x: '0', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{
              y: 0,
              opacity: 0,
            }}
          >
            {content}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}
