import { AllScreenBlur } from '../slides/AllScreenBlur'

const ErrorOverlay = ({
  errorType,
  message,
  onClose,
}: {
  errorType: 'server' | 'network'
  message: string
  onClose: () => void
}) => {
  return (
    <AllScreenBlur dimApp close={onClose}>
      <div className="w-full bg-red z-[999] p-4 rounded-[10px]">
        <h1>{message}</h1>
      </div>
    </AllScreenBlur>
  )
}

export default ErrorOverlay
