import { Booking } from '../types'
import { create } from 'zustand'

// Define a type for the user object

// Define the store's state
type ExistingBookingState = {
  booking: Booking | undefined
  loadingBooking: boolean
  setBooking: (booking: Booking | undefined) => void
  removeBooking: () => void
  setLoadingBooking: (loadingBooking: boolean) => void
}

// Create the store
const useExistingBookingStore = create<ExistingBookingState>((set) => ({
  booking: undefined,
  loadingBooking: false,
  setBooking: (booking: Booking | undefined) => set({ booking }),
  removeBooking: () => set({ booking: undefined }),
  setLoadingBooking: (loadingBooking) => set({ loadingBooking }),
}))

export default useExistingBookingStore
