import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
  CarouselNext,
} from '@/components/upseat-ui/tickets/OnboardingTicketCarousel'
import { Dispatch, useEffect, useState } from 'react'

import Cookie from '@/assets/Cookie.png'
import Lottie from 'lottie-react'
import useScreenSize from '@/hooks/useScreenSize'

interface OnboardingTicketProps {
  setOnboardingTicket: Dispatch<React.SetStateAction<boolean>>
}

export function OnboardingTicket({
  setOnboardingTicket,
}: OnboardingTicketProps) {
  const screenSize = useScreenSize()
  const [animationStep1, setAnimationStep1] = useState<any>()
  const [animationStep2, setAnimationStep2] = useState<any>()

  useEffect(() => {
    fetch(
      'https://res.cloudinary.com/dzqafjknm/raw/upload/v1725932996/Step1_raiqgj.json',
    )
      .then((response) => response.json())
      .then((data) => setAnimationStep1(data))
      .catch((error) => console.error('Error loading animation 1:', error))

    fetch(
      'https://res.cloudinary.com/dzqafjknm/raw/upload/v1725932988/Step2_dcvzno.json',
    )
      .then((response) => response.json())
      .then((data) => setAnimationStep2(data))
      .catch((error) => console.error('Error loading animation 2:', error))
  }, [])

  return (
    <div
      id="onboarding-ticket-card"
      className={`
        rounded-[40px] bg-purple8 flex flex-col items-center justify-between ${screenSize.width > 1024 ? 'w-[25vw]' : 'w-[85vw]'} h-[85svh] px-4 py-6`}
    >
      {animationStep1 && animationStep2 && (
        <Carousel className="w-full h-full flex flex-col items-center justify-between gap-4">
          <CarouselContent className="relative h-auto gap-4">
            <CarouselItem className="flex flex-col items-center basis-full">
              <Lottie animationData={animationStep1} loop={true} />
              <p
                id="onboarding-ticket-card-text-1"
                className="text-white text-balance text-center h-auto flex items-start justify-center pt-4"
              >
                {
                  'Whenever you book tickets directly from the theatre, they’ll appear right here.'
                }
              </p>
            </CarouselItem>
            {/* <CarouselItem className="flex flex-col items-center basis-full">
              <Lottie animationData={animationStep2} loop={true} />
              <p
                id="onboarding-ticket-card-text-2"
                className="text-white text-balance text-center h-auto px-2 flex items-start justify-center pt-4"
              >
                {'Upgrades go live 48hrs before your show.'}
              </p>
            </CarouselItem> */}
            <CarouselItem className="flex flex-col items-center basis-full">
              <span className="py-4">
                <img src={Cookie} alt="cookie" className="w-[170px]" />
              </span>
              <p className="font-bold">{`Cookies you’ll actually want`}</p>
              <p
                id="onboarding-ticket-card-text-2"
                className="text-white text-balance text-center h-auto px-2 pt-4"
              >
                Experiences are better when they’re made just for you. By
                clicking Accept, you consent to our{' '}
                <span className="text-purple4">{`cookie policy`}</span>.
              </p>
            </CarouselItem>
          </CarouselContent>
          <CarouselDots />
          <CarouselNext
            setOnboardingTicket={setOnboardingTicket as () => void}
            variant={'primaryYellow'}
          />
        </Carousel>
      )}
    </div>
  )
}
