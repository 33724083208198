import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '../tickets/qrCodeCarousel'

import Barcode from 'react-barcode'
import { Booking } from '@/types'
import { QRCodeSVG } from 'qrcode.react'
import { TicketButton } from './TicketButton'
import { TicketInfo } from './TicketInfo'
import { UpSeatLogoSmall } from '@/assets/upseat_small'
import useScreenSize from '@/hooks/useScreenSize'
import { useState } from 'react'

type TicketCardBackFaceProps = {
  bookingTickets: Booking
  upgradeTicket?: (
    order_id: string,
    instance_uid: string,
    ticketPrice: number,
  ) => void
}

export function TicketCardBackFace({
  bookingTickets,
  upgradeTicket,
}: TicketCardBackFaceProps) {
  const screenSize = useScreenSize()
  const [carouselCurrentIndex, setCarouselCurrentIndex] = useState(0)

  bookingTickets.tickets.sort((a, b) => {
    // Extract only the numbers from seat_name using regular expressions
    const matchA = a.seat_name.match(/\d+/)
    const matchB = b.seat_name.match(/\d+/)

    // Check if the match is not null before accessing
    const numA = matchA ? parseInt(matchA[0]) : 0
    const numB = matchB ? parseInt(matchB[0]) : 0

    // Compare the numeric parts
    return numA - numB
  })

  return (
    <>
      <div
        className={`${bookingTickets.upseated ? `shadow-[0_0_10px_0px_yellow]` : `shadow-[0_0_12px_0px_#CDC3FB]`}
        rounded-[35px] bg-white rounded-[35px] relative flex flex-col items-center
        w-full h-full max-h-[80svh] max-w-[85vw] mb-[10px] px-[5%]`}
      >
        <div className="h-[72.5%] w-[inherit] flex flex-col justify-center">
          <div className="flex flex-col w-full items-center px-2 [&>div]:flex [&>div]:justify-between [&>div]:py-2 [&>div]:w-full">
            <div className=" border-b border-grey2 border-solid max-w-[80%]">
              <p className="uppercase text-purple font-light text-sm">{`section`}</p>
              <p className={`text-purple text-sm font-semibold`}>
                {bookingTickets.tickets[0].plan_name}
              </p>
            </div>
            <div className="border-b border-grey2 border-solid max-w-[80%]">
              <p className="uppercase text-purple font-light text-sm">{`row`}</p>
              <p className={`text-purple text-sm font-semibold`}>
                {bookingTickets.tickets[0].seat_name.replace(/[^a-zA-Z]/g, '')}
              </p>
            </div>

            <div className="max-w-[80%]">
              <p className="uppercase text-purple font-light text-sm">{`seat`}</p>
              <p className="text-purple text-sm font-semibold">
                {bookingTickets.tickets[carouselCurrentIndex].seat_name.replace(
                  /\D/g,
                  '',
                )}
              </p>
            </div>
            <Carousel
              className={`w-full ${bookingTickets.tickets.length < 2 && 'pointer-events-none'}`}
            >
              <CarouselContent
                className="m-0"
                setCarouselCurrentIndex={setCarouselCurrentIndex}
              >
                {bookingTickets.tickets.map((seat) => {
                  return (
                    <CarouselItem
                      className="flex flex-col w-full justify-center items-center basis-full pl-0"
                      key={seat.uid}
                    >
                      {bookingTickets.upseated && !seat.barcode ? (
                        <>
                          <div className="flex flex-col gap-1 justify-center items-center pt-2">
                            <div className="flex items-center relative rounded-[20px] border-[4px] border-purple3 p-1 mb-1">
                              <div className="flex flex-col items-center blur-[3px] w-full [&>svg]:w-full">
                                {bookingTickets.config?.is_qr ? (
                                  <QRCodeSVG value={seat.barcode} size={196} />
                                ) : (
                                  <Barcode value={seat.barcode} />
                                )}
                              </div>
                              <span className="absolute bg-purple3 py-4 left-[-1px] w-[101%] font-heading text-sm">
                                <h4 className="text-center text-balance px-4">
                                  {`${
                                    bookingTickets.config?.is_qr
                                      ? 'QR code'
                                      : 'Barcode'
                                  }
                                      will be released before the show`}
                                </h4>
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="flex flex-col items-center py-2 w-[80%] h-full max-w-[100%]">
                            {bookingTickets.config?.is_qr ? (
                              <QRCodeSVG value={seat.barcode} size={196} />
                            ) : (
                              <Barcode value={seat.barcode} />
                            )}
                          </div>
                        </>
                      )}
                    </CarouselItem>
                  )
                })}
              </CarouselContent>
            </Carousel>
          </div>
        </div>
        <div className="bg-purple6 absolute  border-t border-purple2 border-dashed flex flex-col items-center justify-around w-full h-[27.5%] bottom-0 rounded-[0_0_35px_35px]">
          <div
            className={`bg-purple w-[28px] h-[28px] rotate-180 rounded-l-full absolute left-[-14px] top-[-15px]`}
          >
            <div
              className={`rounded-l-full w-[14px] h-full absolute left-0 top-0`}
              style={{
                boxShadow: bookingTickets.upseated
                  ? `inset 4px 0 4px rgba(255, 255, 0, 0.4)`
                  : `inset 4px 0 4px rgba(205, 195, 251, 0.4)`,
              }}
            ></div>
          </div>

          <div
            className={`bg-purple w-[28px] h-[28px] rounded-l-full absolute right-[-12px] top-[-15px]`}
          >
            <div
              className={`rounded-l-full w-[16px] h-full absolute left-0 top-0`}
              style={{
                boxShadow: bookingTickets.upseated
                  ? `inset 4px 0 4px rgba(255, 255, 0, 0.4)`
                  : `inset 4px 0 4px rgba(205, 195, 251, 0.4)`,
              }}
            ></div>
          </div>

          <div className="flex flex-col h-full items-center justify-around my-4">
            <TicketInfo ticketInfo={bookingTickets} upgraded embeddedInTicket />

            {navigator.onLine &&
            [0, 1, 4].includes(bookingTickets.state.code) ? (
              !bookingTickets.upseated ? (
                <TicketButton
                  ticketState={bookingTickets.state.code}
                  onClick={() => {
                    if (bookingTickets.state.code === 0) {
                      upgradeTicket &&
                        upgradeTicket(
                          bookingTickets.order_id,
                          bookingTickets.instance_uid,
                          bookingTickets.tickets[0].price,
                        )
                    }
                  }}
                />
              ) : (
                <div className="flex gap-10 justify-between items-center">
                  <div className="flex items-center text-purple">
                    <figure className="[&>svg]:w-auto [&>svg]:h-[2rem] [&>svg]:relative [&>svg]:top-[2px] pr-[1px]">
                      <UpSeatLogoSmall />
                    </figure>
                    <h5 className="text-[19px]">{`graded`}</h5>
                  </div>
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}
