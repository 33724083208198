import { useEffect, useRef, useState } from 'react'

import { Barcode } from 'lucide-react'
import { Booking } from '@/types'

type MemoryCardProps = {
  bookingTickets: Booking
  isActive: boolean
}

export function MemoryCard({ isActive }: MemoryCardProps) {
  const [videoOn, setVideoOn] = useState<boolean>(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  useEffect(() => {
    const video = videoRef.current

    if (isActive && video) {
      video
        .play()
        .catch((error) => console.error('Video playback failed', error))
      setIsPlaying(true)
    } else if (video) {
      video.pause()
      setIsPlaying(false)
    }

    return () => {
      if (video) {
        video.pause() // Ensures video is paused when component unmounts or re-renders
        setIsPlaying(false)
      }
    }
  }, [isActive])

  return (
    <div className="w-full flex justify-center">
      <div
        id="memory-card"
        className={`
        rounded-[35px] bg-purple shadow-[0_0_1px_0px_#000000] rounded-[35px] flex flex-col items-center w-full h-fit max-w-[90vw]`}
      >
        <div className="overflow-hidden w-full h-[80svh] rounded-[35px] m-[-1px]">
          <video
            autoPlay={isActive}
            muted={true}
            playsInline={true}
            ref={videoRef}
            className="w-full rounded-[35px]"
            onClick={togglePlayPause}
            onEnded={() => setVideoOn(false)}
            onError={() => setVideoOn(false)}
          >
            <source
              src="https://res.cloudinary.com/dzqafjknm/video/upload/v1722968129/UpSeat_Demo_Video_rbfx7c.mp4"
              type="video/mp4"
            />
          </video>
          <p></p>
        </div>
      </div>
    </div>
  )
}
