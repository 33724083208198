import { useEffect, useState } from 'react'

import { UpSeatLogoLightSmall } from '@/assets/upseat_light_small'
import { motion } from 'framer-motion'

interface SeatsLoaderProps {
  dark?: boolean
}

const SeatsLoader = ({ dark }: SeatsLoaderProps) => {
  const [active, setActive] = useState<number>(0)
  const items: number[] = [0, 1, 2]

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prevActive) =>
        prevActive < items.length - 1 ? prevActive + 1 : 0,
      )
    }, 300)

    return () => clearInterval(interval)
  }, [items.length])

  return (
    <motion.div
      className="flex flex-col gap-4 items-center justify-center w-full h-[100vh]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
    >
      <h3 className="flex text-h3 px-4 text-center">
        {`searching for`}
        <figure className="pl-2 [&>svg]:w-auto [&>svg]:h-[2.5rem] [&>svg]:relative [&>svg]:right-[2px]">
          <UpSeatLogoLightSmall />
        </figure>
        {`seats`}
      </h3>

      <div className="loading-indicator flex gap-1 justify-center items-center">
        {items.map((number) => (
          <div
            key={number}
            className={`h-4 w-4 border transition rounded-sm ${
              dark ? `border-purple5` : `border-yellow`
            } ${
              active === number
                ? dark
                  ? 'bg-purple2'
                  : 'bg-yellow'
                : 'bg-transparent'
            }`}
          ></div>
        ))}
      </div>
    </motion.div>
  )
}

export default SeatsLoader
