import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { transitionEasing, transitionEasingReverse } from '../lib/global-vars'
import { formatInTimeZone } from 'date-fns-tz'
import { Ticket } from '@/types'
import { v4 as uuidv4 } from 'uuid'
import { get, keys } from 'idb-keyval'
import { Booking } from '@/types'

export const transition = {
  ease: transitionEasing,
  duration: 0.325,
  delay: 0.325,
}
export const transitionOut = {
  ease: transitionEasingReverse,
  duration: 0.125,
}

export const upSeatLogoLink = 'https://i.ibb.co/L5s6T0C/Frame-23.png'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
export const formattedDate = (date: Date) => {
  return formatInTimeZone(date, 'Europe/London', 'EEE d MMM, h:mmaaa')
}

export const formatDateToWeekDayDDMMYY = (
  dateObject: Date | string | undefined,
) => {
  if (dateObject === undefined) {
    return 'n/a'
  } else if (!(dateObject instanceof Date)) {
    dateObject = new Date(dateObject)
  }
  // Date components
  const day = dateObject.getDate().toString().padStart(2, '0')
  const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][
    dateObject.getDay()
  ]
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0')
  const year = dateObject.getFullYear().toString().slice(2)

  // Time components
  const hours = dateObject.getHours().toString().padStart(2, '0')
  const minutes = dateObject.getMinutes().toString().padStart(2, '0')
  const seconds = dateObject.getSeconds().toString().padStart(2, '0')

  if (!day || day === 'NaN') {
    return ''
  } else {
    return `${weekday} ${day}.${month}.${year} at ${hours}:${minutes}`
  }
}

export const loadingSeatsCounter = (
  seats: number,
  onUpdate: (count: number) => void,
) => {
  let count = 0

  const interval = setInterval(() => {
    count++
    onUpdate(count)

    if (count >= seats || count >= 25) {
      clearInterval(interval)
    }
  }, 200)

  if (count >= 25) {
    return seats
  }

  return count
}

export const seatNamesFormatted = (tickets: Ticket[]) => {
  const uniquePlanNames = Array.from(
    new Set(tickets.map((ticket) => ticket.plan_name)),
  )

  return uniquePlanNames.map((planName) => {
    const seats = tickets
      .filter((ticket) => ticket.plan_name === planName)
      .map((ticket) => ticket.seat_name)

    // Function to group seat names by row and format them
    const seatsByRow = {} as any
    seats.forEach((seat) => {
      const row = seat.replace(/[0-9]/g, '') // Extract row (remove numbers)
      if (!seatsByRow[row]) {
        seatsByRow[row] = []
      }
      seatsByRow[row].push(seat)
    })

    const formattedSeats = Object.entries(seatsByRow)
      .map(([row, seatNumbers]: any) => {
        const minSeat = Math.min(
          ...seatNumbers.map((seat: any) => parseInt(seat.replace(/\D/g, ''))),
        )
        const maxSeat = Math.max(
          ...seatNumbers.map((seat: any) => parseInt(seat.replace(/\D/g, ''))),
        )
        // eslint-disable-next-line no-useless-concat
        return `${row}${minSeat}${tickets.length > 1 ? `-` + maxSeat : ''}`
      })
      .join(', ')

    return uniquePlanNames + ` ` + formattedSeats
  })
}

export const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case 'GBP':
      return '£'
    case 'USD':
      return '$'
    case 'EUR':
      return '€'
    default:
      return '£'
  }
}

export const cleanTokenFromLocalStorage = () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
}

export function filterNumbers(str: string) {
  let numbers = ''
  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i)
    if (code >= 48 && code <= 57) {
      numbers += str[i]
    }
  }
  return numbers
}

export const getUniqueId = () => {
  return uuidv4()
}

export const pastToday = (date: Date) => {
  const today = new Date()
  return date < today
}

export const fetchAllBookingsFromDB = async (): Promise<Booking[]> => {
  const dbKeys = await keys() // Retrieve all keys from the database
  const bookings: Booking[] = []

  for (const key of dbKeys) {
    const value = await get(key) // Fetch the value for each key
    if (value) {
      bookings.push(value as Booking) // Cast the value to Booking and push to the array
    }
  }

  return bookings // Return the array of bookings
}

// Function to delete object store
export const deleteDatabase = async (dbName: string) => {
  if (!window.indexedDB) {
    console.error('IndexedDB not supported in this browser')
    return
  }
  const request = await window.indexedDB.deleteDatabase(dbName)

  return request
}

// Get Booking/Order state
export const getTicketState = (booking: Booking) => {
  switch (booking.state.code) {
    case 0:
      return 'Upgradable'
    case 1:
      return 'Upseated'
    case 4:
      return 'UpgradeToBeAvailable'
    default:
      return 'ViewTicket'
  }
}

// Function to start an interval
export const startInterval = (
  ref: React.MutableRefObject<number | null>,
  callback: TimerHandler,
  delay?: number,
) => {
  if (ref.current !== null) {
    window.clearInterval(ref.current)
  }
  ref.current = window.setInterval(callback, delay)
}

// Function to stop an interval
export const stopInterval = (ref: React.MutableRefObject<number | null>) => {
  if (ref.current !== null) {
    window.clearInterval(ref.current)
    ref.current = null
  }
}
