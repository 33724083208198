import { requestWithRetry } from '../axios'

export const getPlan = async (order_uid: string) => {
  try {
    return await requestWithRetry(
      `events/seats/plan?order_uid=${order_uid}`,
      `get`,
    )
  } catch (error: any) {
    return error.response?.data
  }
}
