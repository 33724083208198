export const GoogleIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_879_8748)">
      <path
        d="M4.43242 12.5875L3.73625 15.1864L1.19176 15.2402C0.431328 13.8298 0 12.2161 0 10.5013C0 8.84301 0.403281 7.27926 1.11812 5.90234H1.11867L3.38398 6.31766L4.37633 8.56938C4.16863 9.17488 4.05543 9.82488 4.05543 10.5013C4.05551 11.2353 4.18848 11.9386 4.43242 12.5875Z"
        fill="#FBBB00"
      />
      <path
        d="M19.8261 8.63281C19.941 9.23773 20.0009 9.86246 20.0009 10.5009C20.0009 11.2169 19.9256 11.9152 19.7822 12.5889C19.2954 14.8812 18.0234 16.8828 16.2613 18.2993L16.2608 18.2987L13.4075 18.1532L13.0037 15.6323C14.1729 14.9466 15.0866 13.8735 15.568 12.5889H10.2207V8.63281H15.646H19.8261Z"
        fill="#518EF8"
      />
      <path
        d="M16.2595 18.2975L16.2601 18.298C14.5464 19.6755 12.3694 20.4996 9.99965 20.4996C6.19141 20.4996 2.88043 18.3711 1.19141 15.2387L4.43207 12.5859C5.27656 14.8398 7.45074 16.4442 9.99965 16.4442C11.0952 16.4442 12.1216 16.148 13.0024 15.631L16.2595 18.2975Z"
        fill="#28B446"
      />
      <path
        d="M16.382 2.80219L13.1425 5.45437C12.2309 4.88461 11.1534 4.55547 9.99906 4.55547C7.39246 4.55547 5.17762 6.23348 4.37543 8.56812L1.11773 5.90109H1.11719C2.78148 2.6923 6.13422 0.5 9.99906 0.5C12.4254 0.5 14.6502 1.3643 16.382 2.80219Z"
        fill="#F14336"
      />
    </g>
    <defs>
      <clipPath id="clip0_879_8748">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)
