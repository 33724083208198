import { requestWithRetry } from '../axios'

export const deleteBasket = async (upgrade_uid: string) => {
  try {
    return await requestWithRetry('basket/tickets', 'delete', {
      upgrade_uid,
    })
  } catch (error: any) {
    return error.response?.data
  }
}
