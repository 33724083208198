import { Button } from '@/components/ui/button'
import { ChevronLeft } from 'lucide-react'

type HeadlineBarProps = {
  title: string | JSX.Element
  goBack?: Function
  action?: Function
  actionIcon?: JSX.Element
  centered?: boolean
}

const HeadlineBar = ({
  title,
  goBack,
  action,
  actionIcon,
  centered,
}: HeadlineBarProps) => {
  return (
    <div
      className={`headline-bar h-[10svh] w-full flex items-center relative z-10 px-5 mb-[-1px]
      ${centered ? 'w-full justify-center' : 'justify-between'}
    `}
    >
      <div className="flex items-center">
        {goBack ? (
          <Button
            variant="textOnly"
            className="text-white p-1 mr-2"
            onClick={() => goBack()}
          >
            <ChevronLeft className="h-6 w-6" />
          </Button>
        ) : null}
        <h4 className="text-h4">{title}</h4>
      </div>
      {action && actionIcon ? (
        <Button
          className="absolute right-0"
          variant="textOnly"
          onClick={() => action()}
        >
          {actionIcon}
        </Button>
      ) : null}
    </div>
  )
}

export default HeadlineBar
