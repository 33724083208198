import { AllScreenSlide } from '../slides/AllScreenSlide'
import { Button } from '@/components/ui/button'
import { GoogleIcon } from '@/assets/GoogleIcon'
import { Plus } from 'lucide-react'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useUserStore } from '@/context/useUserStore'

export interface SignUpSlideProps {
  onClose: () => void
  setLoginPage?: () => void
}
export const SignUpSlide = ({ onClose, setLoginPage }: SignUpSlideProps) => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const { setUser, setSignUpForm } = useUserStore()

  // const GoogleLoginSubmit = useGoogleLogin({
  //   onSuccess: async (res: any) => {
  //     setError(null)
  //     setLoadingLogin(true)

  //     try {
  //       const loginResponse = await socialLogin(
  //         res.access_token,
  //         'google-oauth2',
  //       )
  //       localStorage.setItem('access_token', loginResponse.access_token)
  //       localStorage.setItem('refresh_token', loginResponse.refresh_token)

  //       const userResponse = await getUser()
  //       setUser({
  //         id: userResponse.uid,
  //         username: userResponse.email.split('@')[0],
  //         firstname: userResponse.first_name,
  //         lastname: userResponse.last_name,
  //         email: userResponse.email,
  //         phoneNumber: userResponse.phone_number ?? '',
  //         countryCode: { acronym: 'GB', code: '+44', digits: '44' },
  //       })

  //       syncBooking()
  //         .then()
  //         .catch((error) => {
  //           console.log('Sync Booking Error:', error)
  //           callRouteWithDirection('/wallet', true, 1)
  //         })
  //     } catch (error: any) {
  //       setLoadingLogin(false)
  //       if (error.response && error.response.status === 400) {
  //         setError(`Wrong email or password`)
  //       } else {
  //         setError(
  //           error.response
  //             ? error.response.error_description
  //             : 'An error occurred',
  //         )
  //       }
  //       if (error.message === 'Request timed out') {
  //         setServerError(true)
  //       }
  //     } finally {
  //       setLoadingLogin(false)
  //     }
  //   },
  //   onError: (errorResponse: any) => {
  //     setError(errorResponse.error_description)
  //     return
  //   },
  // })

  return (
    <AllScreenSlide dimApp close={onClose}>
      <h3 className="text-purple text-h3 pr-4">{`the show start here`}</h3>
      <div
        className="bg-white flex flex-col text-purple h-[100%] pb-24"
        data-testid="sign-up-form"
      >
        <Plus
          width={36}
          height={36}
          onClick={onClose}
          className="z-10 absolute top-6 right-4 rotate-45"
        />
        <div>
          <div className="flex flex-col gap-2 my-6">
            <Button
              variant="secondaryDark"
              className="w-full text-[black] border-[#747775] font-normal py-4"
              // onClick={() => GoogleLoginSubmit()}
            >
              <GoogleIcon />
              {`Continue with Google`}
            </Button>
            {/* <Button
              variant="secondaryDark"
              className="w-full text-[black] border-[#747775] font-normal py-4"
            >
              <AppleIcon />
              {`Sign up with Apple`}
            </Button> */}
            <Button
              variant="secondaryDark"
              data-testid="sign-up-email-button"
              className="w-full font-normal py-4"
              onClick={() => {
                callRouteWithDirection('/sign-up', false, 2)
                setSignUpForm(true)
              }}
            >
              {`Sign up with email address`}
            </Button>
          </div>

          <p className="text-center text-[12px] leading-normal text-balance">
            {`By signing up you agree to UpSeat’s `}
          </p>
          <p className="text-center text-[12px] leading-normal text-balance">
            <a
              className="text-purple4"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.notion.so/upseat/Our-legals-273016cae1d24dd4802f65e788c47631"
            >{`Terms & Conditions`}</a>
            {` and `}
            <a
              className="text-purple4"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.notion.so/upseat/Our-legals-273016cae1d24dd4802f65e788c47631"
            >{`Privacy Policy`}</a>
          </p>
        </div>
        <div className="flex text-center items-center justify-center gap-2 mt-12">
          <p>{`Already have an account?`}</p>
          <Button
            variant="secondaryDark"
            className="py-1 px-4"
            onClick={() => callRouteWithDirection('/login', false, 2)}
          >
            {`Login`}
          </Button>
        </div>
      </div>
    </AllScreenSlide>
  )
}
