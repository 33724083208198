import { useState } from 'react'

export interface Indexes {
  previousIndex: number
  currentIndex: number
  nextIndex: number
  afternextIndex: number
}

export const useIndexes = (walletLength: number) => {
  const [indexes, setIndexes] = useState<Indexes>({
    previousIndex: 0,
    currentIndex: 0,
    nextIndex: 1,
    afternextIndex: 2,
  })

  const handleCardTransition = () => {
    setIndexes((prevState) => {
      if (walletLength && prevState.currentIndex >= walletLength - 1) {
        return {
          previousIndex: walletLength - 1,
          currentIndex: 0,
          nextIndex: 1,
          afternextIndex: 2,
        }
      } else {
        return {
          previousIndex: prevState.currentIndex,
          currentIndex: prevState.currentIndex + 1,
          nextIndex: (prevState.currentIndex + 2) % walletLength,
          afternextIndex: (prevState.currentIndex + 3) % walletLength,
        }
      }
    })
  }

  return { indexes, handleCardTransition }
}
