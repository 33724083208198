import { ReactNode } from 'react'
import { User } from '../types'
import { create } from 'zustand'

export const tempUser: User = {
  id: '0',
  username: 'JoeDoe',
  firstname: 'Joe',
  lastname: 'Doe',
  email: 'test@gmail.com',
  countryCode: {
    code: '+44',
    acronym: 'GB',
    digits: '44',
  },
  phoneNumber: '1234567890',
}

// Define the store's state
type UserState = {
  user: User | undefined
  setUser: (user: User) => void
  unsetUser: () => void
  signUpForm: boolean
  setSignUpForm: (value: boolean) => void
}

// Create the store
export const useUserStore = create<UserState>((set) => ({
  user: undefined, // initial state
  setUser: (user) => set({ user }),
  unsetUser: () => set({ user: undefined }),
  signUpForm: false,
  setSignUpForm: (value) => set({ signUpForm: value }),
}))

export const UserStoreProvider = ({ children }: { children: ReactNode }) => {
  return <>{children}</>
}
