import { Plus, PlusIcon } from 'lucide-react'
import { useEffect, useState } from 'react'

import { Button } from '@/components/ui/button'
import { CartScreenSlide } from '../slides/CartScreenSlide'
import { InfoIcon } from '@/assets/infoIcon'
import { InfoIconDark } from '@/assets/infoIconDark'
import LoadingIndicator from '../seatMap/LoadingIndicator'
import { Modal } from '../modals'
import { Seat } from '@/types'
import { createBasket } from '@/service/Basket/postBasket'
import { deleteBasket } from '@/service/Basket/deleteBasket'
import { getConsecutiveSeatGroups } from '@/pages/upBooking/upBooking/Upbooking'
import { postTickets } from '@/service/Basket/postTickets'
import { useAppStore } from '@/context/useAppStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useCartStore } from '@/context/useCartStore'
import useEventStore from '@/context/useEventStore'
import useExistingBookingStore from '@/context/useExistingBookingStore'
import { useTicketsStore } from '@/context/useTicketsStore'

export const CartSlide = () => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const { priceBands, selectedSeats, setSelectedSeats } = useEventStore()
  const { booking } = useExistingBookingStore()
  const { setServerError } = useAppStore()
  const { basket, setBasket, removeBasket } = useTicketsStore()
  const { setSeatsPendingPayment } = useCartStore()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>()
  const [infoModal, setInfoModal] = useState<boolean>(false)

  useEffect(() => {}, [selectedSeats, setSelectedSeats])

  useEffect(() => {
    if (infoModal) {
      setTimeout(() => {
        setInfoModal(false)
      }, 7000)
    }
  }, [infoModal])

  const deleteCart = async () => {
    setSelectedSeats([])
    if (booking && basket) {
      await deleteBasket(basket?.upgrade_uid)
      await createBasket(booking.instance_uid, booking.uid)
        .then((res) => {
          setBasket({
            uid: res.basket.uid,
            upgrade_uid: res.upgrade.uid,
            instance_uid: res.basket.instance_uid,
            total_discount: res.basket.total_discount,
            total_price: res.basket.total_price,
            payment_confirmed: res.basket.payment_confirmed,
            seats: res.basket.seats,
            expires_utc: res.basket.expires_utc,
          })

          return res.data
        })
        .catch((err) => {
          console.log('postNewBasket Error:', err)
          setServerError(true)
        })
    }
  }

  const confirmSeatSelection = async () => {
    setLoading(true)

    if (basket && priceBands) {
      await postTickets(
        selectedSeats.map((seat) => seat.seat_id).join(','),
        selectedSeats[0].band_uid,
        basket.upgrade_uid,
      )
        .then((res: any) => {
          if (res) {
            setBasket({
              ...basket,
              seats: res.data.seats,
              total_price: res.data.total_price,
              expires_utc: res.data.expires_utc,
            })
            callRouteWithDirection('/booking-summary', false, 1)
          }
        })
        .catch((err: any) => {
          console.log('postTickets Error:', err)
          setError('Error adding seats')
          setTimeout(() => {
            setError(null)
            deleteCart()
            removeBasket()
          }, 3000)
          setLoading(false)
        })
        .finally(() => {
          setLoading(false)
        })
    }
    setSeatsPendingPayment(selectedSeats)
  }

  const renderCartPrices = (selectedSeats: Seat[]) => {
    const selectedSeatsByRow = selectedSeats.reduce(
      (acc, seat) => {
        if (!acc[seat.row]) {
          acc[seat.row] = []
        }

        // Check if there are already seats in the row with a different location
        const hasDifferentLocation = acc[seat.row].some(
          (existingSeat) => existingSeat.location !== seat.location,
        )

        if (!hasDifferentLocation) {
          // If the location of the current seat matches with the existing seats in the row, add it to the array
          acc[seat.row].push(seat)
        } else {
          // If the location is different, create a new array for seats with this location
          if (!acc[seat.row + '_' + seat.location]) {
            acc[seat.row + '_' + seat.location] = []
          }
          // Add the current seat to the new array
          acc[seat.row + '_' + seat.location].push(seat)
        }

        return acc
      },
      {} as Record<string, Seat[]>,
    )

    const rows = Object.keys(selectedSeatsByRow)
    return rows.map((row, index) => {
      const seats = selectedSeatsByRow[row]

      // Check if seats are consecutive
      const consecutiveGroups = getConsecutiveSeatGroups(
        seats.sort((a: any, b: any) => a.number - b.number),
      )

      if (consecutiveGroups.length > 0) {
        // Consecutive seats found, render in '1-5' format
        const consecutiveSeatsInfo = consecutiveGroups
          .map((group) => {
            return `${group[0].number}-${group[group.length - 1].number}`
          })
          .sort((a: any, b: any) => a - b) // Sort consecutive seats in ascending order

        // Include non-consecutive seats in the same paragraph
        const nonConsecutiveSeatsInfo = seats
          .filter((seat) => !consecutiveGroups[0].flat().includes(seat))
          .sort((a: any, b: any) => a.number - b.number) // Sort non-consecutive seats in ascending order
          .map((seat) => `${seat.number}`)

        return (
          <div
            key={`${index}`}
            className="flex justify-between items-start pl-1"
          >
            <div className="flex flex-col font-light gap-1">
              <div className="font-semibold text-nowrap">
                <p className="flex justify-between pb-1">{seats[0].location}</p>
                <span className="absolute border-b border-grey w-[85%]"></span>
              </div>
              <p className="flex gap-1">
                <span className="text-balance">{`Was`}</span>
              </p>
              <p className="flex gap-1 font-bold">
                <span className="text-balance">{`Now`}</span>
              </p>
            </div>

            <div className="flex flex-col gap-1">
              <span className="text-balance font-bold text-end">
                {`Row ${row.split('_')[0]},`}
                {` `}
                {consecutiveSeatsInfo.join(' / ')}
                {nonConsecutiveSeatsInfo.length > 0 &&
                consecutiveSeatsInfo.length > 0
                  ? ' / '
                  : ''}
                {nonConsecutiveSeatsInfo.join(' / ')}
              </span>
              <div className="flex gap-2 items-center justify-end pt-2">
                <p className="text-body flex gap-1 line-through">
                  <span>{`${seats.length} x`}</span>
                  <span>{`£${seats[0].original_price.toFixed(2)}`}</span>
                </p>
              </div>
              <div className="flex gap-2 items-center font-bold">
                <p className="flex gap-1 font-light items-center py-1">
                  <span className="text-[10px] font-normal px-2 bg-red text-nowrap rounded-[20px]">{`${seats[0].discount * 100}% off`}</span>
                  {/* <span>{`${seats.length}x`}</span>
                <span className="relative">
                  {`£${booking?.tickets[0].price.toFixed(2)}`}
                  <span className="absolute w-full border-b top-[53%] left-[0%]"></span>
                </span> */}
                </p>
                <p className="text-body flex gap-1">
                  <span>{`${seats.length} x`}</span>
                  <span>{`£${seats[0].upgrade_price.toFixed(2)}`}</span>
                </p>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div
            key={`${index}`}
            className="flex justify-between items-start pl-1"
          >
            <div className="flex flex-col font-light gap-1">
              <div className="font-semibold text-nowrap">
                <p className="flex justify-between pb-1">{seats[0].location}</p>
                <span className="absolute border-b border-grey w-[85%]"></span>
              </div>
              <p className="flex gap-1">
                <span className="text-balance">{`Was`}</span>
              </p>
              <p className="flex gap-1 font-bold">
                <span className="text-balance">{`Now`}</span>
              </p>
            </div>

            <div className="flex flex-col gap-1">
              <span className="text-balance font-bold text-end">
                {`Row ${row.split('_')[0]},`}
                {` `}
                {`${seats.map((seat) => seat.number.toString()).join(' / ')}`}
              </span>
              <div className="flex gap-2 items-center justify-end pt-2">
                <p className="text-body flex gap-1 line-through">
                  <span>{`${seats.length} x`}</span>
                  <span>{`£${seats[0].original_price.toFixed(2)}`}</span>
                </p>
              </div>
              <div className="flex gap-2 items-center font-bold">
                <p className="flex gap-1 font-light items-center py-1">
                  <span className="text-[10px] font-normal px-2 bg-red text-nowrap rounded-[20px]">{`${seats[0].discount * 100}% off`}</span>
                </p>
                <p className="text-body flex gap-1">
                  <span>{`${seats.length} x`}</span>
                  <span>{`£${seats[0].upgrade_price.toFixed(2)}`}</span>
                </p>
              </div>
            </div>
          </div>
        )
      }
    })
  }

  return (
    <>
      {selectedSeats.length && (
        <CartScreenSlide
          dimApp
          dark
          seatsSelected={
            booking && booking.tickets.length === selectedSeats.length
          }
          infoModal={infoModal}
        >
          <div className="relative bg-purple flex flex-col text-white h-[100%] gap-2">
            {booking && booking.tickets.length !== selectedSeats.length && (
              <div className="relative bg-purple3 rounded-[20px] flex flex-col text-white text-sm p-4">
                {`You’ve only selected ${selectedSeats.length} out of ${booking && booking.tickets.length} seats.`}
              </div>
            )}

            {!error ? (
              <>
                <div className="flex flex-col gap-1">
                  <div className="p-2 flex items-center justify-between">
                    <p className="text-sm capitalize">{`new seats`}</p>
                    <p
                      className="relative text-sm flex gap-1 justify-end"
                      onClick={() => setInfoModal(true)}
                    >
                      <InfoIconDark />
                    </p>
                  </div>
                  <div className={`border rounded-[20px] p-2 px-3`}>
                    {selectedSeats && (
                      <div className={`flex items-start`}>
                        <div className="flex flex-col gap-2 justify-between w-full">
                          {renderCartPrices(selectedSeats)}
                        </div>
                        <PlusIcon
                          size={20}
                          className="relative top-[-1px] ml-[10px] bg-purple rounded-full rotate-45"
                          onClick={() => deleteCart()}
                        />
                      </div>
                    )}
                  </div>

                  <Modal
                    modalOpen={infoModal}
                    content={
                      <div className="relative bg-purple3 rounded-[20px] p-2 px-4 flex gap-2 mt-1">
                        <span className="pt-[2px]">
                          <InfoIcon />
                        </span>
                        <p className="w-fit text-white text-sm leading-snug text-balance">
                          {`This is the price of upgrading here from your current seat. It's in addition to the original cost of your ticket.`}
                        </p>
                        <Plus
                          color="white"
                          data-testid="info-box-closeIcon"
                          onClick={() => setInfoModal(false)}
                          style={{
                            rotate: '45deg',
                            position: 'absolute',
                            right: '12px',
                            top: '7px',
                          }}
                          width={16}
                          height={16}
                        />
                      </div>
                    }
                  />
                </div>
                {booking &&
                  booking.tickets.length === selectedSeats.length &&
                  (!loading && basket ? (
                    <Button
                      variant="primaryYellow"
                      className="px-8 py-4 w-full"
                      onClick={() => confirmSeatSelection()}
                    >{`Confirm`}</Button>
                  ) : (
                    <div className="my-8">
                      <LoadingIndicator />
                    </div>
                  ))}
              </>
            ) : (
              <div className="flex flex-col gap-2 bg-red rounded-[20px]">
                <h4 className="text-white p-4 px-6 text-center">{`${error} :(`}</h4>
              </div>
            )}
          </div>
        </CartScreenSlide>
      )}
    </>
  )
}
