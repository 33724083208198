import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Input, Textarea } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  contactUsFormSchema,
  contactUsFormSchemaType,
} from '@/lib/validation-schemas/contactUsFormSchema'
import { mobileDevices, ticketingIssues } from '@/lib/global-vars'

import { Button } from '@/components/ui/button'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { getUniqueId } from '@/utils'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useUserStore } from '@/context/useUserStore'
import { zodResolver } from '@hookform/resolvers/zod'

export const ContactUs = () => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const { user } = useUserStore()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<contactUsFormSchemaType>({
    resolver: zodResolver(contactUsFormSchema),
  })
  const onSubmit: SubmitHandler<contactUsFormSchemaType> = (data) => {
    console.log('DATA SENT ->', data)
  }
  return (
    <DefaultLayout>
      <RouteTransition>
        <div className="flex flex-col justify-start gap-4">
          <HeadlineBar
            title={`contact us`}
            goBack={() => callRouteWithDirection('/help-centre', true, 3)}
          />
        </div>
        <main className="bg-white overflow-scroll flex flex-col gap-2 text-purple h-[90svh] p-4 py-6">
          <p className="font-body">{`A member of our team will get back to you as soon as possible.`}</p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="bg-white overflow-scroll flex flex-col gap-4 text-purple h-[90svh] py-4"
            autoComplete={'off'}
          >
            <div className="flex gap-2">
              <Controller
                name="firstName"
                control={control}
                defaultValue={user?.firstname || ''}
                render={({ field }) => (
                  <div className="flex flex-col">
                    <Input
                      className={`${
                        errors.firstName?.message && 'border-red'
                      } py-4`}
                      placeholder="First Name"
                      {...field}
                    />
                    {errors.firstName && (
                      <p className="text-[12px] text-pretty pl-2 text-red">
                        {errors.firstName.message}
                      </p>
                    )}
                  </div>
                )}
              />

              <Controller
                name="lastName"
                control={control}
                defaultValue={user?.lastname || ''}
                render={({ field }) => (
                  <div className="flex flex-col">
                    <Input
                      className={`${
                        errors.lastName?.message && 'border-red'
                      } py-4`}
                      placeholder="Last Name"
                      {...field}
                    />

                    {errors.lastName && (
                      <p className="text-[12px] text-pretty pl-2 text-red">
                        {errors.lastName.message}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>

            <Controller
              name="email"
              control={control}
              defaultValue={user?.email || ''}
              render={({ field }) => (
                <div className="flex flex-col">
                  <Input
                    className={`${errors.email?.message && 'border-red'} py-4`}
                    placeholder="Email Address"
                    {...field}
                    onChange={(e) => {
                      const lowercaseValue = e.target.value.toLowerCase()
                      field.onChange(lowercaseValue)
                    }}
                  />

                  {errors.email && (
                    <p className="text-[12px] text-pretty pl-2 text-red">
                      {errors.email.message}
                    </p>
                  )}
                </div>
              )}
            />

            <p className="uppercase text-sm pl-2">
              {'what device are you using?'}
            </p>

            <Controller
              name="device"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="flex flex-col">
                  <Select
                    {...field}
                    onValueChange={(value) => {
                      field.onChange(value)
                    }}
                  >
                    <SelectTrigger
                      className={`${
                        errors.device?.message && 'border-red'
                      } py-4 text-left w-full text-purple`}
                    >
                      <SelectValue placeholder="Select a device" />
                    </SelectTrigger>
                    <SelectContent className="w-[90vw] text-purple border-none py-2">
                      <SelectGroup>
                        {mobileDevices.map((device, index) => (
                          <SelectItem key={getUniqueId()} value={device}>
                            {device}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  {errors.device && (
                    <p className="text-[12px] text-pretty pl-2 text-red">
                      {errors.device.message}
                    </p>
                  )}
                </div>
              )}
            />

            <p className="uppercase text-sm pl-2">
              {'what best describes your issue?'}
            </p>

            <Controller
              name="issue"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="flex flex-col">
                  <Select
                    {...field}
                    onValueChange={(value) => {
                      field.onChange(value)
                    }}
                  >
                    <SelectTrigger
                      className={`${
                        errors.issue?.message && 'border-red'
                      } py-4 text-left w-full text-purple`}
                    >
                      <SelectValue placeholder="Select an issue" />
                    </SelectTrigger>
                    <SelectContent className="w-[90vw] text-purple border-none py-2">
                      <SelectGroup>
                        {ticketingIssues.map((issue) => (
                          <SelectItem key={getUniqueId()} value={issue}>
                            {issue}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  {errors.issue && (
                    <p className="text-[12px] text-pretty pl-2 text-red">
                      {errors.issue.message}
                    </p>
                  )}
                </div>
              )}
            />

            <Controller
              name="issueDescription"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="flex flex-col">
                  <Textarea
                    className={`${
                      errors.issueDescription?.message && 'border-red'
                    } py-4 rounded-[20px] h-[7.5rem]`}
                    placeholder="Describe your issue here"
                    {...field}
                  />
                  {errors.issueDescription && (
                    <p className="text-[12px] text-pretty pl-2 text-red">
                      {errors.issueDescription.message}
                    </p>
                  )}
                </div>
              )}
            />

            <Button
              type="submit"
              variant="primaryDark"
              className="w-full font-normal py-4 mt-4"
            >{`Submit form`}</Button>
          </form>
        </main>
      </RouteTransition>
    </DefaultLayout>
  )
}
