import { z } from 'zod'

export const paymentCardFormSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  number: z.string().min(1, { message: 'Card Number is required' }),
  expiryDate: z.string().min(1, { message: 'Expiry Date is required' }),
  cvc: z.string().min(1, { message: 'CVC is required' }),
})

export type paymentCardFormSchemaType = z.infer<typeof paymentCardFormSchema>
