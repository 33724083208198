export const UpSeatTiles = () => (
  <svg
    width="268"
    height="268"
    viewBox="0 0 268 248"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.3">
      <rect
        x="273.028"
        y="78.8594"
        width="88"
        height="88"
        rx="16"
        transform="rotate(180 273.028 78.8594)"
        fill="#3E3663"
      />
      <rect
        x="180.515"
        y="78.8594"
        width="88"
        height="88"
        rx="16"
        transform="rotate(180 180.515 78.8594)"
        fill="#5C5094"
      />
      <rect
        x="88"
        y="78.8594"
        width="88"
        height="88"
        rx="16"
        transform="rotate(180 88 78.8594)"
        fill="#5C5094"
      />
      <rect
        x="274.57"
        y="172.574"
        width="88"
        height="88"
        rx="16"
        transform="rotate(180 274.57 172.574)"
        fill="#5C5094"
      />
      <rect
        x="182.57"
        y="172.574"
        width="88"
        height="88"
        rx="16"
        transform="rotate(180 182.57 172.574)"
        fill="#3E3663"
      />
      <rect
        x="278.57"
        y="266.291"
        width="88"
        height="88"
        rx="16"
        transform="rotate(180 278.57 266.291)"
        fill="#3E3663"
      />
    </g>
  </svg>
)
