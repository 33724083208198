import { motion, useSpring } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'

interface CardFlipperProps {
  FrontFace: React.ReactNode
  BackFace: React.ReactNode
  isCurrentCard?: boolean
  index?: number
}

export const CardFlipper = ({
  FrontFace,
  BackFace,
  isCurrentCard,
  index,
}: CardFlipperProps) => {
  const [rotateXaxis, setRotateXaxis] = useState(0)
  const [rotateYaxis, setRotateYaxis] = useState(0)
  const ref = useRef(null)
  const [isFlipped, setIsFlipped] = useState(false)
  const [isFlippedInactive, setIsFlippedInactive] = useState(true)
  const timeoutRef = useRef<number | null>(null)

  //Spring animation parameters
  const spring = {
    mass: 5,
    tension: 500,
    friction: 80,
  }

  const dx = useSpring(0, spring)
  const dy = useSpring(0, spring)

  useEffect(() => {
    dx.set(-rotateXaxis)
    dy.set(rotateYaxis)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotateXaxis, rotateYaxis])

  useEffect(() => {
    if (localStorage.getItem('user_onboarding')) {
      setTimeout(() => {
        index === 0 && setIsFlipped(true)
        setIsFlippedInactive(false)
      }, 2000)

      localStorage.removeItem('user_onboarding')
    }

    setIsFlippedInactive(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isCurrentCard) {
      setIsFlipped(false)
    }
  }, [isCurrentCard])

  const handleFlip = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
    isCurrentCard && setIsFlipped((prevState) => !prevState)
  }

  return (
    <div className="w-full h-full relative">
      <motion.div
        transition={spring}
        style={{
          perspective: '1200px',
          transformStyle: 'preserve-3d',
          width: `100%`,
          height: `100%`,
        }}
      >
        <motion.div
          ref={ref}
          transition={spring}
          style={{
            width: '100%',
            height: '100%',
            rotateX: dx,
            rotateY: dy,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              perspective: '1200px',
              transformStyle: 'preserve-3d',
              width: '100%',
              height: '100%',
              maxWidth: '400px',
              maxHeight: '800px',
            }}
          >
            <motion.div
              animate={{ rotateY: isFlipped ? -180 : 0 }}
              transition={spring}
              style={{
                width: '100%',
                height: '100%',
                zIndex: isFlipped ? 0 : 1,
                backfaceVisibility: 'hidden',
                position: 'absolute',
              }}
              onClick={() => !isFlippedInactive && handleFlip()}
              className="front left-0 w-full flex items-baseline justify-center px-6"
            >
              {FrontFace}
            </motion.div>
            <motion.div
              initial={{ rotateY: 180 }}
              animate={{ rotateY: isFlipped ? 0 : 180 }}
              transition={spring}
              style={{
                width: '100%',
                height: '100%',
                zIndex: isFlipped ? 1 : 0,
                backfaceVisibility: 'hidden',
                position: 'absolute',
              }}
              onClick={() => !isFlippedInactive && handleFlip()}
              className="back left-0 w-full flex items-baseline justify-center px-6"
            >
              {BackFace}
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  )
}
