import React, { ReactNode } from 'react'

import { UpSeatTiles } from '@/assets/upseat-tiles'
import useScreenSize from '@/hooks/useScreenSize'

type DefaultLayoutProps = {
  children: ReactNode
  plain?: boolean
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children, plain }) => {
  const screenSize = useScreenSize()

  return (
    <div
      className={`app-view w-full overflow-x-hidden bg-purple ${screenSize.width > 1024 && 'pl-[33vw] pr-[33vw]'}`}
    >
      {!plain && (
        <figure className="fixed z-0 right-0 top-0 opacity-70">
          <UpSeatTiles />
        </figure>
      )}
      {children}
    </div>
  )
}

export default DefaultLayout
