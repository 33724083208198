import { Input } from '../../ui/input'
import { SearchIcon } from 'lucide-react'

interface SearchInputProps {
  search: string
  setSearch: (search: string) => void
  dataTestid?: string
}

export const SearchInput = ({
  search,
  setSearch,
  dataTestid,
}: SearchInputProps) => {
  return (
    <div
      className="relative mb-2 [&>input]:placeholder:text-purple1 [&>input]:placeholder:font-light"
      data-testid={dataTestid}
    >
      <SearchIcon className="absolute top-[18px] left-4 text-purple4 w-5 h-5" />
      <Input
        className="py-4 pl-12"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="How can we help you?"
      />
    </div>
  )
}
