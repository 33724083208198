import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { motion, usePresence } from 'framer-motion'
import useNavigationStore from '@/context/useNavigationStore'
import { transitionEasing } from '@/lib/global-vars'

type RouteTransitionProps = {
  children: React.ReactNode
}

const RouteTransition = ({ children }: RouteTransitionProps) => {
  const location = useLocation()

  const transitionDuration = 0.25

  const [isPresent, safeToRemove] = usePresence()
  const { backNav, setBackNav } = useNavigationStore()

  useEffect(() => {
    !isPresent && setTimeout(safeToRemove, transitionDuration * 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent])

  const transition = {
    ease: transitionEasing,
    duration: transitionDuration,
  }

  const [animation, setAnimation] = useState({
    initial: { x: backNav ? '-100%' : '100%', opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: backNav ? '100%' : '-100%', opacity: 0 },
  })

  useEffect(() => {
    setAnimation({
      initial: { x: backNav ? '-100%' : '100%', opacity: 0 },
      animate: { x: 0, opacity: 1 },
      exit: { x: backNav ? '100%' : '-100%', opacity: 0 },
    })
  }, [backNav])

  return (
    <motion.div
      className="w-full h-full"
      variants={animation}
      key={location.key}
      transition={transition}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {children}
    </motion.div>
  )
}

export default RouteTransition
