import * as Sentry from '@sentry/react'

import { User } from '@/types'
import { uniqueId } from 'cypress/types/lodash'
import { useEffect } from 'react'

// Update the function to accept props
type SentryInitializationProps = {
  user: User
}

const SentryInitialization = ({ user }: SentryInitializationProps) => {
  useEffect(() => {
    Sentry.init({
      dsn: `https://a2bc5037b4ae6ee6b51e4661a61e36c4@o4507260359540736.ingest.de.sentry.io/${process.env.REACT_APP_SENTRY_ID as string}`,
      debug: true,
      release: `upseat-ui`,
      initialScope: {
        user: { id: user.id, email: user.email },
      },
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default SentryInitialization
