import { create } from 'zustand'
import { set } from 'idb-keyval'

// Define the store's state
type AppState = {
  loadingApp: boolean
  initialLoading: boolean
  currency: string
  textAlert: boolean
  emailAlert: boolean
  serverError: boolean
  networkError: boolean
  MVPsignUpSlider: boolean
  cookiesConsent: boolean
  sideMenu: boolean
  setTextAlert: (textAlert: boolean) => void
  setEmailAlert: (emailAlert: boolean) => void
  setCurrency: (currency: string) => void
  setLoadingApp: (loadingApp: boolean) => void
  setInitialLoading: (firstLoading: boolean) => void
  setServerError: (serverError: boolean) => void
  setNetworkError: (networkError: boolean) => void
  setMVPsignUpSlider: (MVPsignUpSlider: boolean) => void
  setCookiesConsent: (cookiesConsent: boolean) => void
  setSideMenu: (sideMenu: boolean) => void
}

// Create the store
export const useAppStore = create<AppState>((set) => ({
  loadingApp: false, // initial state
  initialLoading: true,
  currency: 'GBP',
  textAlert: false,
  emailAlert: false,
  serverError: false,
  networkError: false,
  MVPsignUpSlider: false,
  cookiesConsent: false,
  sideMenu: false,
  setTextAlert: (textAlert) => set({ textAlert }),
  setEmailAlert: (emailAlert) => set({ emailAlert }),
  setCurrency: (currency) => set({ currency }),
  setLoadingApp: (loadingApp) => set({ loadingApp }),
  setInitialLoading: (initialLoading) => set({ initialLoading }),
  setServerError: (serverError) => set({ serverError }),
  setNetworkError: (networkError) => set({ networkError }),
  setMVPsignUpSlider: (MVPsignUpSlider) => set({ MVPsignUpSlider }),
  setCookiesConsent: (cookiesConsent) => set({ cookiesConsent }),
  setSideMenu: (sideMenu) => set({ sideMenu }),
}))
