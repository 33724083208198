export const ProfileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
  >
    <path
      d="M1 8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7C7.53043 6 8.03914 6.21071 8.41421 6.58579C8.78929 6.96086 9 7.46957 9 8C9 8.26522 8.89464 8.51957 8.70711 8.70711C8.51957 8.89464 8.26522 9 8 9H2C1.73478 9 1.48043 8.89464 1.29289 8.70711C1.10536 8.51957 1 8.26522 1 8Z"
      stroke="#F4FF68"
      strokeLinejoin="round"
    />
    <path
      d="M5 4C5.82843 4 6.5 3.32843 6.5 2.5C6.5 1.67157 5.82843 1 5 1C4.17157 1 3.5 1.67157 3.5 2.5C3.5 3.32843 4.17157 4 5 4Z"
      stroke="#F4FF68"
    />
  </svg>
)
