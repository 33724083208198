export const Circle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="393"
    height="433"
    viewBox="0 0 393 433"
    fill="none"
  >
    <g filter="url(#filter0_d_1584_11348)">
      <mask id="path-1-inside-1_1584_11348" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 16C4 7.16343 11.1634 0 20 0C28.8366 0 36 7.16344 36 16V231C36 242.046 44.9543 251 56 251H336C347.046 251 356 242.046 356 231V16C356 7.16344 363.163 0 372 0V0C380.837 0 388 7.16344 388 16V250.932C388 250.969 388.031 251 388.068 251V251C388.106 251 388.136 251.031 388.136 251.068V375C388.136 375.794 388.09 376.577 388 377.347V377.347C388 387.097 380.097 395 370.347 395H368.172C368.16 395 368.148 395 368.136 395L356 395L36 395H24.0937H24C12.9543 395 4 386.046 4 375V16Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 16C4 7.16343 11.1634 0 20 0C28.8366 0 36 7.16344 36 16V231C36 242.046 44.9543 251 56 251H336C347.046 251 356 242.046 356 231V16C356 7.16344 363.163 0 372 0V0C380.837 0 388 7.16344 388 16V250.932C388 250.969 388.031 251 388.068 251V251C388.106 251 388.136 251.031 388.136 251.068V375C388.136 375.794 388.09 376.577 388 377.347V377.347C388 387.097 380.097 395 370.347 395H368.172C368.16 395 368.148 395 368.136 395L356 395L36 395H24.0937H24C12.9543 395 4 386.046 4 375V16Z"
        fill="white"
      />
      <path
        d="M368.136 395L368.136 396H368.136V395ZM356 395L356 394L356 394L356 395ZM36 395L36 396H36L36 395ZM20 -1C10.6112 -1 3 6.61115 3 16H5C5 7.71572 11.7157 1 20 1V-1ZM37 16C37 6.61116 29.3888 -1 20 -1V1C28.2843 1 35 7.71573 35 16H37ZM37 231V16H35V231H37ZM336 250H56V252H336V250ZM357 231V16H355V231H357ZM387 16V250.932H389V16H387ZM389.136 375V251.068H387.136V375H389.136ZM388.993 377.463C389.088 376.655 389.136 375.833 389.136 375H387.136C387.136 375.755 387.092 376.5 387.007 377.231L388.993 377.463ZM370.347 394H368.172V396H370.347V394ZM368.136 396C368.143 396 368.149 396 368.155 396C368.161 396 368.166 396 368.172 396V394C368.165 394 368.158 394 368.153 394C368.147 394 368.141 394 368.136 394V396ZM368.136 394L356 394L356 396L368.136 396L368.136 394ZM36 396L356 396L356 394L36 394L36 396ZM24.0937 396H36V394H24.0937V396ZM24 396H24.0937V394H24V396ZM3 375C3 386.598 12.402 396 24 396V394C13.5066 394 5 385.493 5 375H3ZM3 16V375H5V16H3ZM387 377.347C387 386.544 379.544 394 370.347 394V396C380.649 396 389 387.649 389 377.347H387ZM388.068 252C387.554 252 387.136 251.583 387.136 251.068H389.136C389.136 250.478 388.658 250 388.068 250V252ZM387 250.932C387 251.522 387.478 252 388.068 252V250C388.583 250 389 250.417 389 250.932H387ZM372 1C380.284 1 387 7.71573 387 16H389C389 6.61116 381.389 -1 372 -1V1ZM357 16C357 7.71573 363.716 1 372 1V-1C362.611 -1 355 6.61116 355 16H357ZM336 252C347.598 252 357 242.598 357 231H355C355 241.493 346.493 250 336 250V252ZM35 231C35 242.598 44.402 252 56 252V250C45.5066 250 37 241.493 37 231H35Z"
        fill="#D5D5D5"
        mask="url(#path-1-inside-1_1584_11348)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1584_11348"
        x="0"
        y="0"
        width="392.137"
        height="403"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1584_11348"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1584_11348"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
