import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import {
  deleteDatabase,
  fetchAllBookingsFromDB,
  pastToday,
  startInterval,
  stopInterval,
  transition,
} from '@/utils'

import { Booking } from '@/types'
import { Button } from '@/components/ui/button'
import { CardList } from './CardList'
import LoadingIndicator from '../LoadingIndicator'
import { Skeleton } from '@/components/ui/skeleton'
import { TicketsIcon } from '@/assets/navIcons/TabBarIcons'
import { getWallet } from '@/service/Wallet/getWallet'
import { set } from 'idb-keyval'
import { syncBooking } from '@/service/Booking/syncBooking'
import { syncSilent } from '@/service/Booking/syncSilent'
import { useAppStore } from '@/context/useAppStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useDrag } from '@use-gesture/react'
import { useIndexes } from '../../tickets/useIndexes'
import useScreenSize from '@/hooks/useScreenSize'
import { useSpring } from '@react-spring/web'
import { useTicketsStore } from '@/context/useTicketsStore'
import { useUserStore } from '@/context/useUserStore'

interface Indexes {
  previousIndex: number
  currentIndex: number
  nextIndex: number
  afternextIndex: number
}

interface MemoryStackProps {}

export const determineClasses = (
  walletLength: number,
  indexes: Indexes,
  cardIndex: number,
) => {
  if (indexes.currentIndex === cardIndex) return 'active'
  if (indexes.nextIndex === cardIndex) return 'next'
  if (indexes.previousIndex === cardIndex && walletLength > 3) return 'prev'
  if (
    indexes.afternextIndex === cardIndex ||
    (indexes.previousIndex === cardIndex && walletLength === 3)
  )
    return 'afternext'
  return 'inactive'
}

export const MemoryStack = () => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const { user } = useUserStore()
  const { wallet, setWallet } = useTicketsStore()
  const screenSize = useScreenSize()
  const getWalletIntervalRef = useRef<number | null>(null)
  const syncSilentIntervalRef = useRef<number | null>(null)
  const [loadingTickets, setLoadingTickets] = useState(true)
  const [syncLoading, setSyncLoading] = useState(false)
  const { indexes, handleCardTransition } = useIndexes(
    wallet ? wallet.length : 0,
  )

  const [{ y, rotateX, opacity }, api] = useSpring(() => ({
    y: 0,
    rotateX: 0,
    scale: 1,
    opacity: 1,
  }))

  const bind = useDrag(
    ({ down, movement: [, my] }) => {
      const rotation = -my / 40
      const scaling = down ? 0.95 : 1
      const opacityValue = down ? 1 : 1 // You can adjust opacity here if needed

      api.start(
        my < 0 && wallet && wallet.length > 1
          ? {
              y: down ? my : 0,
              rotateX: down ? rotation : 0,
              scale: scaling,
              opacity: opacityValue, // Maintain opacity during drag
            }
          : {},
      )

      if (!down && my < 250) {
        if (my < -100) {
          api.start({
            y: -500,
            rotateX: rotation,
            opacity: 1,
            scale: 1,
            config: { duration: 200, friction: 10 },
            onRest: () => {
              api.start({
                y: -1000,
                rotateX: 0,
                opacity: 0,
                scale: 0,
                config: { duration: 200 },
                onRest: () => {
                  api.start({
                    y: 300,
                    rotateX: (rotation / 2) * -1,
                    opacity: 0,
                    scale: 0,
                    config: { duration: 50 },
                    onRest: () => {
                      api.start({
                        y: -5,
                        rotateX: 0,
                        opacity: 0.95,
                        scale: 0.95,
                        config: { duration: 300 },
                        onRest: () => {
                          api.start({
                            y: 0,
                            opacity: 1,
                            scale: 1,
                            config: { duration: 300 },
                          })
                        },
                      })
                    },
                  })
                },
              })
            },
          })

          handleCardTransition()
        } else {
          api.start({ y: 0, rotateX: 0, scale: 1, opacity: 1 })
        }
      }
    },
    { axis: 'y' },
  )

  const formatBookingToWallet = (bookings: Booking[]) => {
    const validBookings = bookings.filter(
      (booking: Booking) => booking.tickets.length,
    )

    const formattedBookings = validBookings.map((booking: Booking) => ({
      order_id: booking.order_id,
      uid: booking.uid,
      state: booking.state,
      config: booking.config,
      instance_uid: booking.tickets[0].instance_uid,
      event_name: booking.tickets[0].event_name,
      theatre_name: booking.tickets[0].theatre_name,
      start_time: booking.tickets[0].start_time,
      thumbnail_url: booking.tickets[0].thumbnail_url,
      tickets: booking.tickets,
      invalidated: booking.invalidated,
      upseated: booking.upseated,
    }))

    return formattedBookings
  }

  const loadUserTickets = () => {
    getWallet()
      .then(async (res) => {
        if (!res || !res?.length) {
          setTimeout(() => {
            setWallet([])
            setLoadingTickets(false)
          }, 2000)
        }
        // const formattedBookings = formatBookingToWallet(res).filter(
        //   (booking) => !pastToday(new Date(booking.start_time)),
        // )

        const formattedBookings = formatBookingToWallet(res)

        if (
          !wallet ||
          res.length !== formattedBookings.length ||
          res.some(
            (r: { tickets: { barcode: string }[] }, i: number) =>
              r.tickets[0].barcode !== formattedBookings[i].tickets[0].barcode,
          )
        ) {
          for (const booking of formattedBookings) {
            await set(booking.order_id, booking)
          }

          // setWallet(
          //   formattedBookings.filter(
          //     (booking) => !pastToday(new Date(booking.start_time)),
          //   ),
          // )

          setWallet(formattedBookings)
        }
      })
      .catch((error) => {
        console.error('Error fetching wallet', error)
        setSyncLoading(false)
        setTimeout(() => {
          setLoadingTickets(false)
        }, 2000)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  useEffect(() => {
    if (wallet) {
      setSyncLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet])

  useEffect(() => {
    deleteDatabase('keyval-store')
    setSyncLoading(true)
    loadUserTickets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ;(async () => {
      const bookingsDB = await fetchAllBookingsFromDB()
      if (bookingsDB.length) {
        setWallet(bookingsDB)
        setLoadingTickets(false)
      }
    })()

    if (user) {
      startInterval(getWalletIntervalRef, loadUserTickets, 5000)
      startInterval(syncSilentIntervalRef, syncSilent, 15000)
    }

    return () => {
      stopInterval(getWalletIntervalRef)
      stopInterval(syncSilentIntervalRef)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (wallet) {
      setLoadingTickets(false)
    }
  }, [wallet])

  return (
    <AnimatePresence>
      {loadingTickets && !syncLoading ? (
        <motion.div
          transition={{ delay: 0.2, duration: 0.5 }}
          initial={{ opacity: 0, translateY: -10 }}
          animate={{ opacity: 1, translateY: 0 }}
          className={`relative bottom-8 w-full h-full flex justify-center px-6`}
        >
          {/* <Skeleton
            className={`z-30 w-full h-[80svh] z-10 rounded-[35px] bg-purple3/30`}
          /> */}
        </motion.div>
      ) : wallet?.length ? (
        <CardList
          wallet={wallet}
          indexes={indexes}
          bind={bind}
          api={{ y, rotateX, opacity }}
        />
      ) : (
        <div className="relative bottom-8 w-full flex justify-center items-center px-6">
          <div
            className={`absolute bg-purple brightness-110 rounded-[35px] ${screenSize.width > 1024 ? 'w-[25vw]' : 'w-[80vw]'}`}
          ></div>
          <div className="bg-purple brightness-125 w-full h-[82.5svh] rounded-[35px]">
            <div className="z-30 flex flex-col h-full items-center justify-center my-3 [&>svg]:h-[64px] [&>svg]:w-[64px] transition duration-180 ease-default-cubic fill-purple4">
              {TicketsIcon()}
              {syncLoading ? (
                <AnimatePresence>
                  <motion.div
                    transition={transition}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="flex flex-col gap-1 items-center justify-center [&>svg]:h-[64px] [&>svg]:w-[64px]"
                  >
                    <p>{`Syncing your memories`}</p>
                    <span className="my-2">
                      <LoadingIndicator />
                    </span>
                  </motion.div>
                </AnimatePresence>
              ) : (
                <motion.div
                  transition={transition}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="w-full flex flex-col gap-1 justify-center items-center my-2"
                >
                  <p id="wallet-refresh-text">{'Can’t see your tickets?'}</p>
                  <Button
                    variant={'primaryYellow'}
                    className="py-2 rounded-[40px] mt-2"
                    id="wallet-refresh-btn"
                    onClick={async () => {
                      await deleteDatabase('keyval-store').then(async () => {
                        setSyncLoading(true)
                        setWallet(undefined)
                        await syncBooking()
                          .then(async () => {
                            callRouteWithDirection('/wallet', true, 1)
                          })
                          .catch()
                          .finally(() => {
                            setSyncLoading(false)
                          })
                      })
                    }}
                  >{`Refresh`}</Button>
                </motion.div>
              )}
            </div>
          </div>
        </div>
      )}
    </AnimatePresence>
  )
}
