import { create } from 'zustand'

// Define the store's state
type useSeatMapFilterState = {
  discountRange: number[]
  setDiscountRange: (value: number[]) => void
}

// Create the store
export const useSeatMapFilterStore = create<useSeatMapFilterState>((set) => ({
  discountRange: [0, 0],
  setDiscountRange: (discountRange) => set({ discountRange }),
}))
