import { AnimatePresence, easeIn, motion } from 'framer-motion'
import { PriceBand, Seat } from '@/types'
import { useEffect, useState } from 'react'

import DefaultLayout from '@/layouts/DefaultLayout'
import { LoginSlide } from '@/components/upseat-ui/account/LoginSlide'
import { MemoryStack } from '@/components/upseat-ui/seatMap/memories'
import NavLayout from '@/layouts/NavLayout'
import { NavigationHeader } from '@/components/upseat-ui/wallet/NavigationHeader'
import { OnboardingTicket } from '@/components/upseat-ui/wallet/OnboardingTicket'
import SeatsLoader from '@/components/upseat-ui/seatMap/SeatsLoader'
import SeatsNotFound from '@/components/upseat-ui/seatMap/SeatsNotFound'
import { SideMenu } from '@/components/upseat-ui/general/SideMenu'
import { SignUpSlide } from '@/components/upseat-ui/account/SignUpSlide'
import { TicketStack } from '@/components/upseat-ui/tickets'
import { UpSeatLogoLightSmall } from '@/assets/upseat_light_small'
import { getPlan } from '@/service/Seats/getPlan'
import { transition } from '@/utils'
import { useAppStore } from '@/context/useAppStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import useConditionalRender from '@/hooks/useConditionalRender'
import useEventStore from '@/context/useEventStore'
import useExistingBookingStore from '@/context/useExistingBookingStore'
import { useTicketsStore } from '@/context/useTicketsStore'
import { useUserStore } from '@/context/useUserStore'

export const Wallet = () => {
  const { user } = useUserStore()
  const callRouteWithDirection = useCallRouteWithDirection()
  const { loadingApp, sideMenu } = useAppStore()
  const { setBooking } = useExistingBookingStore()
  const { mapSeats, setMapSeats, setPriceBands, setSelectedSeats } =
    useEventStore()
  const { wallet, removeBasket } = useTicketsStore()
  const [loginPage, setLoginPage] = useState<boolean>(false)
  const [signUpPage, setSignUpPage] = useState<boolean>(false)
  const [searchingSeats, setSearchingSeats] = useState<boolean>(false)
  const [onboardingTicket, setOnboardingTicket] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<number>(0)

  useEffect(() => {
    if (localStorage.getItem('user_onboarding')) {
      setOnboardingTicket(true)
    }
  }, [])

  const upgradeTicket = async (bookingUid: string, bookingInstance: string) => {
    setMapSeats(undefined)
    setSearchingSeats(true)
    setSearchingSeats(true)

    await removeBasket()
    await setSelectedSeats([])

    if (wallet) {
      setBooking(wallet.find((booking) => booking.uid === bookingUid))

      const fetchData = async () => {
        let plan
        let attempt = 0
        const maxAttempts = 2

        while (attempt < maxAttempts) {
          try {
            plan = await getPlan(bookingUid)

            if (plan.error) {
              if (attempt < maxAttempts - 1) {
                attempt++
                continue
              } else {
                setSearchingSeats(false)
                return
              }
            }
            break
          } catch (error) {
            if (attempt >= maxAttempts - 1) {
              setSearchingSeats(false)
              return
            }
          }
        }

        if (!plan.bands) return

        const priceBands = plan.bands
          .sort(
            (a: PriceBand, b: PriceBand) =>
              (a.discount_price ?? 0) - (b.discount_price ?? 0),
          )
          .filter(
            (band: PriceBand) => band.discount_price && band.discount_price > 0,
          )

        setPriceBands(priceBands)

        const updatedSeats = plan.seats.map((seat: Seat) => {
          const priceBandIndex = priceBands.findIndex(
            (priceBand: PriceBand) => priceBand.uid === seat.band_uid,
          )
          return {
            ...seat,
            tier: priceBandIndex + 1,
            upgrade_price: priceBands[priceBandIndex]?.discount_price,
            original_price: priceBands[priceBandIndex]?.original_price,
            discount: priceBands[priceBandIndex]?.active_discount,
          }
        })

        setMapSeats(updatedSeats)
      }

      if (bookingInstance) {
        fetchData()
      }
    }
  }

  useEffect(() => {
    if (mapSeats?.length && searchingSeats) {
      if (mapSeats?.some((seat) => seat.band_uid)) {
        callRouteWithDirection('/upbooking', false, 1)
      } else {
        setTimeout(() => {
          setSearchingSeats(false)
          callRouteWithDirection('/wallet', true, 1)
        }, 3000)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapSeats])

  return (
    <div data-testid="tickets" id="tickets" className="w-full">
      {useConditionalRender(
        !loadingApp,
        <>
          <NavLayout overflowHidden plain>
            <SideMenu />
            <div
              className={`flex flex-col justify-start items-center h-lvh ${sideMenu ? 'opacity-20 transition-transform duration-200' : ''}`}
            >
              {!onboardingTicket ? (
                <NavigationHeader
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                  searchingSeats={searchingSeats}
                />
              ) : (
                <div className="py-4 mb-2">
                  <span className="[&>svg]:w-[28px] [&>svg]:h-[28px]">
                    <UpSeatLogoLightSmall />
                  </span>
                </div>
              )}

              {useConditionalRender(
                !!user && !searchingSeats,
                <motion.ul
                  className={`w-full flex flex-col items-center ${!onboardingTicket && 'pt-[9vh]'}`}
                  transition={{
                    ease: easeIn,
                    duration: 0.325,
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  {onboardingTicket ? (
                    <OnboardingTicket
                      setOnboardingTicket={setOnboardingTicket}
                    />
                  ) : activeItem === 0 ? (
                    <TicketStack upgradeTicket={upgradeTicket} />
                  ) : (
                    <MemoryStack />
                  )}
                </motion.ul>,
              )}
            </div>
          </NavLayout>
          {/* Login Slide */}
          {useConditionalRender(
            loginPage,
            <AnimatePresence>
              <LoginSlide
                onClose={() => {
                  setLoginPage(false)
                }}
                setSignUpPage={() => {
                  setSignUpPage(true)
                  setLoginPage(false)
                }}
              />
            </AnimatePresence>,
          )}
          {/* SignUp Slide */}
          {useConditionalRender(
            signUpPage,
            <AnimatePresence>
              {signUpPage && (
                <SignUpSlide
                  onClose={() => setSignUpPage(false)}
                  setLoginPage={() => {
                    setSignUpPage(false)
                    setLoginPage(true)
                  }}
                />
              )}
            </AnimatePresence>,
          )}
        </>,
      )}

      {/* Search Seats */}
      {useConditionalRender(
        searchingSeats,
        <DefaultLayout plain>
          <AnimatePresence>
            <motion.div
              className="search relative w-full h-full overflow-hidden flex flex-col items-center justify-center gap-4"
              transition={transition}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {!mapSeats ? (
                <SeatsLoader />
              ) : !mapSeats?.find((seat) => seat.band_uid) ? (
                <SeatsNotFound />
              ) : null}
            </motion.div>
          </AnimatePresence>
        </DefaultLayout>,
      )}
    </div>
  )
}
