import { Eye, EyeOff, Plus } from 'lucide-react'

import { AllScreenSlide } from '../slides/AllScreenSlide'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import LoadingIndicator from '../seatMap/LoadingIndicator'
import { getUser } from '@/service/User/getUser'
import { postLogin } from '@/service/Login/postLogin'
import { useAppStore } from '@/context/useAppStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useUserStore } from '@/context/useUserStore'

export interface LoginSlideProps {
  onClose: () => void
  setSignUpPage?: () => void
}

export const LoginSlide = ({ onClose, setSignUpPage }: LoginSlideProps) => {
  const navigate = useNavigate()
  const { user, setUser } = useUserStore()
  const { setServerError } = useAppStore()
  const callRouteWithDirection = useCallRouteWithDirection()
  const [loadingLogin, setLoadingLogin] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [error, setError] = useState<React.ReactNode | null>()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const LoginSubmit = async () => {
    setError(null)
    setLoadingLogin(true)

    await postLogin(email, password)
      .then(async (loginResponse) => {
        await localStorage.setItem('access_token', loginResponse.access_token)
        await localStorage.setItem('refresh_token', loginResponse.refresh_token)

        const userResponse = await getUser()
        setUser({
          id: userResponse.uid,
          username: userResponse.email.split('@')[0],
          firstname: userResponse.first_name,
          lastname: userResponse.last_name,
          email: userResponse.email,
          phoneNumber: userResponse.phone_number,
          countryCode: { acronym: 'GB', code: '+44', digits: '44' },
        })

        onClose()
      })
      .catch((error: any) => {
        console.error('Login Error:', error)
        setLoadingLogin(false)

        if (
          error.response &&
          error.response.data.error === 'Account locked - Contact Administrator'
        ) {
          setError(
            <p className="mb-4">
              {`Too many unsuccessful attempts. Please contact us through this`}
              <a
                target="_blank"
                className="underline font-bold text-purple3"
                rel="noopener noreferrer"
                href="https://54dleo9n841.typeform.com/to/zfteuTMG"
              >{` link`}</a>
            </p>,
          )
        } else if (
          error.response &&
          error.response.data.error === 'Beta access only for staff members'
        ) {
          setError(error.response.data.error)
        } else if (
          error.response &&
          error.response.data.error === 'invalid_grant'
        ) {
          setError('Wrong email or password. Please try again.')
        } else if (
          error.response &&
          error.response.data.error === 'invalid_client'
        ) {
          setError('Error on the request. Please try again.')
        } else {
          setError(
            <p>
              {error.response
                ? error.response.data.error_description
                : `An error occurred`}
            </p>,
          )
        }

        if (error.message === 'Request timed out') {
          setServerError(true)
        }
      })
      .finally(() => {
        setLoadingLogin(false)
      })
  }

  // const GoogleLoginSubmit = useGoogleLogin({
  //   onSuccess: async (res: any) => {
  //     setError(null)
  //     setLoadingLogin(true)

  //     try {
  //       const loginResponse = await socialLogin(
  //         res.access_token,
  //         'google-oauth2',
  //       )
  //       localStorage.setItem('access_token', loginResponse.access_token)
  //       localStorage.setItem('refresh_token', loginResponse.refresh_token)

  //       const userResponse = await getUser()
  //       setUser({
  //         id: userResponse.uid,
  //         username: userResponse.email.split('@')[0],
  //         firstname: userResponse.first_name,
  //         lastname: userResponse.last_name,
  //         email: userResponse.email,
  //         phoneNumber: userResponse.phone_number,
  //         countryCode: { acronym: 'GB', code: '+44', digits: '44' },
  //       })

  //       callRouteWithDirection('/wallet', true, 1)
  //     } catch (error: any) {
  //       setLoadingLogin(false)
  //       if (error.response && error.response.status === 400) {
  //         setError(<p>{`Wrong email or password`}</p>)
  //       } else {
  //         setError(
  //           <p>
  //             {error.response
  //               ? error.response.error_description
  //               : `An error occurred`}
  //           </p>,
  //         )
  //       }
  //       if (error.message === 'Request timed out') {
  //         setServerError(true)
  //       }
  //     } finally {
  //       setLoadingLogin(false)
  //     }
  //   },
  //   onError: (errorResponse: any) => {
  //     setError(<p>{errorResponse.error_description}</p>)
  //     return
  //   },
  // })

  return (
    <AllScreenSlide dimApp close={onClose}>
      <h3 className="text-purple text-h3 mb-4">{`login`}</h3>
      <div className="bg-white flex flex-col text-purple h-[100%] pb-24">
        {!loadingLogin && (
          <Plus
            width={36}
            height={36}
            onClick={onClose}
            className="z-10 absolute top-6 right-8 rotate-45"
          />
        )}
        <div data-testid="login-slide">
          <div className="flex flex-col gap-4"></div>
          <div className="flex flex-col gap-2">
            {/* <div className="flex flex-col gap-2 mt-4">
              <Button
                variant="secondaryDark"
                onClick={() => GoogleLoginSubmit()}
                className="w-full text-[black] border-[#747775] font-normal py-3"
              >
                <GoogleIcon />
                {`Continue with Google`}
              </Button> */}
            {/* <Button
                variant="secondaryDark"
                className="w-full text-[black] border-[#747775] font-normal py-3"
              >
                <AppleIcon />
                {`Sign up with Apple`}
              </Button> */}
            {/* </div>
            <div className="flex items-center gap-2">
              <span className="block w-full border-t-[1px] border-grey1 pr-4"></span>
              <p className="uppercase text-grey1 my-2">{`or`}</p>
              <span className="block w-full  border-t-[1px] border-grey1 pl-4"></span>
            </div> */}
            <form
              id="log-in-form"
              className="flex flex-col gap-2"
              data-testid="login-form"
              onSubmit={(e) => {
                e.preventDefault()
                LoginSubmit()
              }}
            >
              <Input
                id="login-form-email"
                className="py-3"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
              />
              <div className="relative">
                <Input
                  id="login-form-password"
                  className="py-3"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute top-3 right-4 bg-gray-300 hover:bg-gray-400 rounded text-sm text-gray-600 font-mono cursor-pointer"
                >
                  {showPassword ? (
                    <Eye color="grey" />
                  ) : (
                    <EyeOff color="grey" />
                  )}
                </label>
              </div>
              {error && (
                <div className="text-red text-center text-[12px] text-balance relative top-[-5px]">
                  {error}
                </div>
              )}
              {!loadingLogin ? (
                <>
                  <div className="w-full flex justify-end">
                    <p
                      onClick={() =>
                        callRouteWithDirection('/forgot-password', false, 1)
                      }
                      className="relative bottom-1 mb-8 text-right text-purple3 w-fit"
                    >{`Forgot your password?`}</p>
                  </div>
                  <Button
                    id="login-form-button"
                    variant="primaryDark"
                    data-testid="login-button"
                    className="w-full"
                    type="submit"
                    disabled={!email || !password}
                  >{`Login`}</Button>
                </>
              ) : (
                <div className="py-8">
                  <LoadingIndicator dark />
                </div>
              )}
            </form>
          </div>
        </div>
        {!loadingLogin && (
          <div
            id="login-sign-up-text"
            className="flex text-center items-center justify-center gap-2 mt-12"
          >
            <p>{`Don’t have an account?`}</p>
            <Button
              id="login-sign-up-button"
              variant="secondaryDark"
              className="py-1 px-4"
              onClick={() => navigate('/sign-up')}
            >
              {`Get started here`}
            </Button>
          </div>
        )}
      </div>
    </AllScreenSlide>
  )
}
