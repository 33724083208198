export const BellIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_457_27825)">
      <path
        d="M29.3126 14.5096C28.5765 14.5096 27.9793 13.9124 27.9793 13.1763C27.9793 9.43634 26.5233 5.92169 23.8792 3.27618C23.358 2.75494 23.358 1.91241 23.8792 1.39093C24.4005 0.86969 25.2432 0.86969 25.7645 1.39093C28.9127 4.53888 30.6458 8.72443 30.6458 13.1763C30.6458 13.9124 30.0486 14.5096 29.3126 14.5096Z"
        fill="#9A86F7"
      />
      <path
        d="M2.64575 14.5096C1.90991 14.5096 1.3125 13.9124 1.3125 13.1763C1.3125 8.72443 3.0459 4.53888 6.19385 1.39093C6.71509 0.86969 7.55786 0.86969 8.0791 1.39093C8.60059 1.91241 8.60059 2.75494 8.0791 3.27618C5.43506 5.92023 3.97925 9.43634 3.97925 13.1763C3.97925 13.9124 3.38184 14.5096 2.64575 14.5096Z"
        fill="#9A86F7"
      />
      <path
        d="M28.4854 22.5508C26.4692 20.8467 25.3132 18.356 25.3132 15.7173V12C25.3132 7.30811 21.8279 3.42407 17.3132 2.77344V1.33325C17.3132 0.595947 16.7158 0 15.98 0C15.2439 0 14.6465 0.595947 14.6465 1.33325V2.77344C10.1306 3.42407 6.64648 7.30811 6.64648 12V15.7173C6.64648 18.356 5.49048 20.8467 3.46265 22.5613C2.94385 23.0054 2.64648 23.6506 2.64648 24.3333C2.64648 25.6201 3.69312 26.6667 4.97998 26.6667H26.98C28.2666 26.6667 29.3132 25.6201 29.3132 24.3333C29.3132 23.6506 29.0159 23.0054 28.4854 22.5508Z"
        fill="#9A86F7"
      />
      <path
        d="M15.9788 32C18.3933 32 20.4133 30.2786 20.8774 28H11.0801C11.5439 30.2786 13.564 32 15.9788 32Z"
        fill="#9A86F7"
      />
    </g>
    <defs>
      <clipPath id="clip0_457_27825">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
