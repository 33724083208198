import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
  paymentCardFormSchema,
  paymentCardFormSchemaType,
} from '@/lib/validation-schemas/paymentCardFormSchema'

import { Button } from '@/components/ui/button'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import { Input } from '@/components/ui/input'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'

const tempUserCC = {
  name: 'John Doe',
  number: '1234 1234 1234 1234',
  expiryDate: '12/24',
  cvc: '123',
}

interface AddPaymentDetailsProps {
  setOpen: (value: boolean) => void
}

export const EditPaymentDetails = ({ setOpen }: AddPaymentDetailsProps) => {
  const [openModal, setOpenModal] = useState(false)
  const [newName, setNewName] = useState<string>(tempUserCC?.name || '')
  const [newNumber, setNewNumber] = useState<string>(tempUserCC.number || '')
  const [newExpiryDate, setNewExpiryDate] = useState<string>(
    tempUserCC.expiryDate || '',
  )
  const [newCvc, setNewCvc] = useState<string>(tempUserCC.cvc || '')
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<paymentCardFormSchemaType>({
    resolver: zodResolver(paymentCardFormSchema),
  })

  const onSubmit: SubmitHandler<paymentCardFormSchemaType> = (data) => {
    console.log('DATA SENT ->', data)
  }

  return (
    <DefaultLayout>
      <RouteTransition>
        <div className="flex flex-col justify-start gap-4">
          <HeadlineBar
            title={`edit payment details`}
            goBack={() => setOpen(false)}
          />
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white overflow-scroll flex flex-col gap-4 text-purple h-[90svh] p-4 py-6"
          autoComplete={'off'}
        >
          <Controller
            name="name"
            control={control}
            defaultValue={tempUserCC.name}
            render={({ field }) => (
              <div className="flex flex-col">
                <p className="uppercase text-[12px] pl-2">{'name on card'}</p>
                <Input
                  className={`${errors.name?.message && 'border-red'} py-4`}
                  placeholder="Email Address"
                  {...field}
                  onChange={(e) => {
                    setNewName(e.target.value)
                    field.onChange(e)
                  }}
                />

                {errors.name && (
                  <p className="text-[12px] text-pretty pl-2 text-red">
                    {errors.name.message}
                  </p>
                )}
              </div>
            )}
          />

          <Controller
            name="number"
            control={control}
            defaultValue={tempUserCC.number}
            render={({ field }) => (
              <div className="flex flex-col">
                <p className="uppercase text-[12px] pl-2">{'card number'}</p>
                <Input
                  className={`${errors.number?.message && 'border-red'} py-4`}
                  placeholder="Email Address"
                  {...field}
                  onChange={(e) => {
                    setNewNumber(e.target.value)
                    field.onChange(e)
                  }}
                />

                {errors.number && (
                  <p className="text-[12px] text-pretty pl-2 text-red">
                    {errors.number.message}
                  </p>
                )}
              </div>
            )}
          />

          <div className="flex gap-2">
            <Controller
              name="expiryDate"
              control={control}
              defaultValue={tempUserCC.expiryDate}
              render={({ field }) => (
                <div className="flex flex-col">
                  <p className="uppercase text-[12px] pl-2">{'expiry date'}</p>
                  <Input
                    className={`${
                      errors.expiryDate?.message && 'border-red'
                    } py-4`}
                    placeholder="First Name"
                    {...field}
                    onChange={(e) => {
                      setNewExpiryDate(e.target.value)
                      field.onChange(e)
                    }}
                  />
                  {errors.expiryDate && (
                    <p className="text-[12px] text-pretty pl-2 text-red">
                      {errors.expiryDate.message}
                    </p>
                  )}
                </div>
              )}
            />

            <Controller
              name="cvc"
              control={control}
              defaultValue={tempUserCC.cvc}
              render={({ field }) => (
                <div className="flex flex-col">
                  <p className="uppercase text-[12px] pl-2">{'cvc'}</p>
                  <Input
                    className={`${errors.cvc?.message && 'border-red'} py-4`}
                    placeholder="Last Name"
                    type="password"
                    {...field}
                    onChange={(e) => {
                      setNewCvc(e.target.value)
                      field.onChange(e)
                    }}
                  />

                  {errors.cvc && (
                    <p className="text-[12px] text-pretty pl-2 text-red">
                      {errors.cvc.message}
                    </p>
                  )}
                </div>
              )}
            />
          </div>

          <Button
            variant="primaryDark"
            disabled={
              tempUserCC.name === newName &&
              tempUserCC.number === newNumber &&
              tempUserCC.expiryDate === newExpiryDate &&
              tempUserCC.cvc === newCvc
            }
            className="w-full"
            onClick={() => setOpen(false)}
          >{`Save changes`}</Button>

          <AlertDialog>
            <AlertDialogTrigger>
              <p className="relative my-2 text-center text-red">{`Delete card`}</p>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle className="font-body font-semibold">
                  Are you sure?
                </AlertDialogTitle>
                <AlertDialogDescription>
                  This card will be removed from your account.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel className="mb-2">
                  No thanks
                </AlertDialogCancel>
                <AlertDialogAction className="bg-red mb-2">{`Delete Card`}</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </form>
      </RouteTransition>
    </DefaultLayout>
  )
}
