export const WarningIconWhite = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="22"
      height="22"
      rx="11"
      stroke="#ffffff"
      strokeWidth="2"
    />
    <path
      d="M10.6262 9.58541C10.5976 9.38833 10.6117 9.18739 10.6676 8.99625C10.7236 8.80511 10.8199 8.62823 10.9503 8.47763C11.0806 8.32704 11.2418 8.20624 11.4229 8.12345C11.604 8.04065 11.8008 7.9978 12 7.9978C12.1991 7.9978 12.396 8.04065 12.5771 8.12345C12.7582 8.20624 12.9194 8.32704 13.0497 8.47763C13.18 8.62823 13.2764 8.80511 13.3323 8.99625C13.3882 9.18739 13.4024 9.38833 13.3738 9.58541L12.9129 12.8098C12.881 13.0292 12.7712 13.2297 12.6035 13.3747C12.4359 13.5198 12.2217 13.5996 12 13.5996C11.7783 13.5996 11.5641 13.5198 11.3964 13.3747C11.2288 13.2297 11.119 13.0292 11.0871 12.8098L10.6262 9.58541ZM12.8001 15.2021C12.8001 15.4143 12.7158 15.6178 12.5657 15.7679C12.4157 15.9179 12.2122 16.0022 12 16.0022C11.7878 16.0022 11.5843 15.9179 11.4342 15.7679C11.2842 15.6178 11.1999 15.4143 11.1999 15.2021C11.1999 14.9899 11.2842 14.7864 11.4342 14.6363C11.5843 14.4863 11.7878 14.402 12 14.402C12.2122 14.402 12.4157 14.4863 12.5657 14.6363C12.7158 14.7864 12.8001 14.9899 12.8001 15.2021Z"
      fill="#ffffff"
    />
  </svg>
)
