import { apiClient } from '../axios'

export const changePassword = async (
  new_password: string,
  token: string,
  confirm_password: string,
) => {
  const response = await apiClient.post('upauth/reset', {
    new_password,
    token,
    confirm_password,
  })
  return response?.data
}
