import { useNavigate } from 'react-router-dom'
import useNavigationStore from '@/context/useNavigationStore'

const useCallRouteWithDirection = () => {
  const navigate = useNavigate()
  const { setBackNav, setActiveSection, pushHistory } = useNavigationStore()

  const callRouteWithDirection = (
    route: string,
    back: boolean,
    tabIndex: number,
  ) => {
    navigate(route)
    setBackNav(back)
    setActiveSection(tabIndex)
    pushHistory(route)
  }

  return callRouteWithDirection
}

export default useCallRouteWithDirection
