import { z } from 'zod'

export const forgotPasswordFormSchema = z
  .object({
    newPassword: z
      .string()
      .min(1, { message: 'New Password is required' })
      .min(1, { message: 'New password confirmation is required' })
      .regex(/.{8,}/, 'Password must be at least 8 characters long')
      .regex(/[A-Z]/, 'Password must have at least one capital letter')
      .regex(/[!@#$%^&*]/, 'Password must have at least one symbol'),
    confirmPassword: z
      .string()
      .min(1, { message: 'New password confirmation is required' }),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'Passwords do not match.',
    path: ['confirmPassword'],
  })

export type forgotPasswordFormSchemaType = z.infer<
  typeof forgotPasswordFormSchema
>
