import { requestWithRetry } from '../axios'

export const updateUser = async ({
  firstName,
  lastName,
  phoneNumber,
}: {
  firstName: string
  lastName: string
  phoneNumber: string
}) => {
  try {
    return await requestWithRetry('upauth/details', 'patch', {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
    })
  } catch (error) {
    // Handle error as per your requirement
    console.log('updateUser Error:', error)
  }
}
